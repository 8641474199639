import styled from 'styled-components';
import { keyframes } from 'styled-components';

const circleBounceDelay = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1);
  }
`

export const Loading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${({width}) => width || 40}px;
  height: ${({height}) => height || 40}px;

  .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: ${({color}) => color ? color : 'rgba(209, 173, 143, 1)'};
    border-radius: 100%;
    animation: ${circleBounceDelay} 1.2s infinite ease-in-out both;
  }
  .sk-circle2 { transform: rotate(30deg); }
  .sk-circle3 { transform: rotate(60deg); }
  .sk-circle4 { transform: rotate(90deg); }
  .sk-circle5 { transform: rotate(120deg); }
  .sk-circle6 { transform: rotate(150deg); }
  .sk-circle7 { transform: rotate(180deg); }
  .sk-circle8 { transform: rotate(210deg); }
  .sk-circle9 { transform: rotate(240deg); }
  .sk-circle10 { transform: rotate(270deg); }
  .sk-circle11 { transform: rotate(300deg); }
  .sk-circle12 { transform: rotate(330deg); }
  .sk-circle2:before { animation-delay: -1.1s; }
  .sk-circle3:before { animation-delay: -1s; }
  .sk-circle4:before { animation-delay: -0.9s; }
  .sk-circle5:before { animation-delay: -0.8s; }
  .sk-circle6:before { animation-delay: -0.7s; }
  .sk-circle7:before { animation-delay: -0.6s; }
  .sk-circle8:before { animation-delay: -0.5s; }
  .sk-circle9:before { animation-delay: -0.4s; }
  .sk-circle10:before { animation-delay: -0.3s; }
  .sk-circle11:before { animation-delay: -0.2s; }
  .sk-circle12:before { animation-delay: -0.1s; }
`;
