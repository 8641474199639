export default class textToImage {
  constructor(style) {
    this.pre = document.createElement('pre')
    this.canvas = document.createElement('canvas')
    this.context = this.canvas.getContext('2d')
    this._style = {
        font: 'Sans-serif',
        align: 'left',
        color: '#000000',
        size: 16,
        background: 'rgba(0, 0, 0, 0)',
        stroke: 0,
        strokeColor: '#FFFFFF',
        lineHeight: '1.2em'
    }
    this.preStyle = ';padding: 0; display: block; position: fixed; top: 100%; overflow: hidden;'
    this.setStyle(style);
    return this;
  }
  
  convert = (message) => {
    message = String(message)
    
    this.pre.innerText = message
    this.pre.setAttribute('style', this._style)
    
    document.body.append(this.pre)
    const lines = message.split('\n')
    let x = this.style.stroke
    let y = this.pre.offsetHeight / lines.length
    const base = y * 0.25
    
    this.canvas.width = this.pre.offsetWidth + (x * 2)
    this.canvas.height = this.pre.offsetHeight
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
    this.context.fillStyle = this.style.background
    this.context.beginPath()
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height)
    this.context.fill()
    this.context.font = `${this.style.size}pt ${this.style.font}`
    this.context.textAlign = this.style.align
    this.context.lineWidth = this.style.stroke
    this.context.strokeStyle = this.style.strokeColor
    this.context.fillStyle = this.style.color

    switch (this.context.textAlign) {
      case 'center':
        x = this.canvas.width / 2
        break
      case 'right':
        x = this.canvas.width - x
        break
      default:
        break
    }

    lines.forEach((line, i) => {
      if (this.style.stroke) {
        this.context.strokeText(line, x, y * (i + 1) - base)
      }
      this.context.fillText(line, x, y * (i + 1) - base)
    })
    document.body.removeChild(this.pre)
  }

  setStyle = style => {
    this.style = style || {};
    for (let key in this._style) {
      if (!this.style[key]) {
        this.style[key] = this._style[key];
      }
    }

    this._style = `font: ${this.style.size}pt ${this.style.font};`;
    this._style += `line-height:${this.style.lineHeight};`;
    this._style += `text-align: ${this.style.align};`;
    this._style += `color: ${this.style.color};`;
    this._style += `background-color: ${this.style.background};`;
    this._style += this.preStyle;
    return this;
  }

  toDataURL = message => {
    if (message) {
      this.convert.call(this, message);
    }
    return this.canvas.toDataURL();
  }

  toImage = (message, callback) => {
    this.convert.call(this, message);
    const img = new Image()
    if (callback) {
      img.onload = callback
    }
    img.src = this.canvas.toDataURL()
    return img
  }
}