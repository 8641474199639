import { call, put, takeLatest } from "redux-saga/effects";

import {
  REQUEST_FETCH_USER_INFO,
  SUCCESS_FETCH_USER_INFO,
  FAIL_FETCH_USER_INFO,
  REQUEST_FETCH_LOGIN,
  SUCCESS_FETCH_LOGIN,
  FAIL_FETCH_LOGIN,
  REQUEST_FETCH_LOGOUT,
  CHANGE_USER_INFO
} from "@/Actions/user";

import { user } from "./api";
import { ETC_IMG_URL } from "@/config";
import TextToImage from "@/Components/TextToImage";
import Toast from "@/Components/Toast";

function* fetchLogin(action) {
  try {
    yield call(user.login, { email: action.email, password: action.password });

    yield put({
      type: SUCCESS_FETCH_LOGIN
    });
  } catch (err) {
    console.log(err);
    let msg = "";
    try {
      msg = err.msg;
    } catch (err) {
      msg = "서버에러가 발생하였습니다.";
    }

    // 모바일 견적서 페이지에서 로그인할 경우 토스트 생성하지 않도록 estimate match 조건문 추가
    !window.location.href.match(/estimate/) && Toast.error(msg);

    const errMsg =
      err && err.msg ? err.msg : "이메일 혹은 비밀번호가 올바르지 않습니다.";
    yield put({
      type: FAIL_FETCH_LOGIN,
      errorMsg: errMsg
    });
  }
}

const textBreaker = text => {
  const temp = text.split(" ");

  try {
    if (temp.length > 1) {
      return temp.join("\n");
    } else if (text.length > 20) {
      const textArr = text.match(/.{1,10}/g);
      return textArr.join("\n");
    }
  } catch (e) {}

  return temp;
};

function* fetchUserInfo(action) {
  try {
    const res = yield call(user.getUser);

    if (!res.logoImg) {
      const textImage = new TextToImage({
        font: "Spoqa Han Sans",
        align: "center",
        color: "#000",
        size: 50,
        background: "#fff"
      });

      const text = textBreaker(res.enterpriseName);
      const img = textImage.toDataURL(text);

      res.logoImgBase64 = img;
    } else {
      res.logoImg = `${ETC_IMG_URL}/logo/${res.logoImg}`;
    }

    // 견적서 페이지에서 로그인할 경우 메인으로 튕기지 않도록 estimate match 조건문 추가
    if (
      action.type === SUCCESS_FETCH_LOGIN &&
      !window.location.href.match(/estimate/)
    ) {
      window.location.href = "/";
    } else {
      yield put({
        type: SUCCESS_FETCH_USER_INFO,
        ...res,
        isLoggedIn: true
      });
    }
  } catch (err) {
    yield put({
      type: FAIL_FETCH_USER_INFO
    });
  }
}

function* fetchLogout() {
  try {
    yield call(user.logout);

    window.location.href = "/";

    // yield put({
    //   type: SUCCESS_FETCH_LOGOUT
    // })
  } catch (err) {
    console.log(err);
    let msg = "";
    try {
      msg = err.msg;
    } catch (err) {
      msg = "서버에러가 발생하였습니다.";
    }
    Toast.error(msg);
  }
}

export default function* watchFetchUser() {
  yield takeLatest(REQUEST_FETCH_LOGIN, fetchLogin);
  yield takeLatest(
    [SUCCESS_FETCH_LOGIN, REQUEST_FETCH_USER_INFO, CHANGE_USER_INFO],
    fetchUserInfo
  );

  yield takeLatest(REQUEST_FETCH_LOGOUT, fetchLogout);
}
