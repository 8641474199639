import { call, put, takeLatest } from "redux-saga/effects";
import { cloneDeep } from "lodash";

import {
  REQUEST_FETCH_PRODUCT,
  SUCCESS_FETCH_PRODUCT,
  FAIL_FETCH_PRODUCT,
  REQUEST_PRODUCT_DETAIL,
  SUCCESS_PRODUCT_DETAIL,
  FAIL_PRODUCT_DETAIL
} from "@/Actions/product";

import { product } from "./api";
import Toast from "@/Components/Toast";

const reduceDetails = (details, seqName, seq) =>
  details.reduce((r, i) => {
    if (!i) {
      return r;
    }
    i[seqName] = seq;
    r.push(i);
    return r;
  }, []);

const convertImgFormat = images =>
  images.reduce((r, i) => {
    r.push(i.name);
    return r;
  }, []);

const convertImgToObject = images =>
  images.reduce((r, i) => {
    const temp = {
      name: i
    };
    r.push(temp);
    return r;
  }, []);

function* fetchProduct(action) {
  const data = action.product;
  const temp = cloneDeep(data);

  const isDvd = action.productType === "dvd";

  let path = "post";
  if (temp.seq) {
    path = "put";
    if (action.productType === "jeju") {
      temp.details = reduceDetails(temp.details, "", temp.seq);
    } else if (action.productType === "main") {
      temp.details = reduceDetails(temp.details, "productMainSeq", temp.seq);
    } else if (action.productType === "simple") {
      temp.details = reduceDetails(temp.details, "productSimpleSeq", temp.seq);
    } else if (action.productType === "dme") {
      temp.details = reduceDetails(temp.details, "productDmeSeq", temp.seq);
    } else if (action.productType === "dvd") {
      temp.details = reduceDetails(temp.details, "productDvdSeq", temp.seq);
    } else if (action.productType === "remind") {
      temp.details = reduceDetails(temp.details, "productRemindSeq", temp.seq);
    } else if (action.productType === "family") {
      temp.details = reduceDetails(temp.details, "productFamilySeq", temp.seq);
    }
  }

  const reviewImg = convertImgFormat(temp.reviewImg);
  const albumImg =
    action.productType === "dme" ? null : convertImgFormat(temp.albumImg);
  const etcImg =
    action.productType === "dme" ? null : convertImgFormat(temp.etcImg);

  temp.details = temp.details.map(detail => {
    if (!detail["frame"] && detail["frameDetail"]) {
      detail["frameDetail"] = null;
    }
    return detail;
  });

  temp.details = JSON.stringify(temp.details);
  temp.reviewImg = JSON.stringify(reviewImg);
  temp.albumImg = JSON.stringify(albumImg);
  temp.etcImg = JSON.stringify(etcImg);
  temp.standardRegions = JSON.stringify(temp.standardRegions);

  try {
    const productSend = isDvd ? product.dvdSend : product.send;

    yield call(productSend, {
      method: path,
      type: action.productType,
      data: temp
    });

    Toast.success("상품 정보를 등록하였습니다.");
    action.history.push("/product/list");

    yield put({
      type: SUCCESS_FETCH_PRODUCT
    });
  } catch (err) {
    console.log(err);
    let msg = "";
    try {
      msg = err.msg;
    } catch (err) {
      msg = "서버에러가 발생하였습니다.";
    }
    Toast.error(msg);

    yield put({
      type: FAIL_FETCH_PRODUCT
    });
  }
}

function* productDetail(action) {
  try {
    const res = yield call(product.detail, {
      type: action.productType,
      seq: action.seq
    });

    const temp = res.product;
    temp.albumImg =
      action.productType === "dme" ? null : convertImgToObject(temp.albumImg);
    temp.reviewImg = convertImgToObject(temp.reviewImg);
    temp.etcImg =
      action.productType === "dme" ? null : convertImgToObject(temp.etcImg);

    yield put({
      type: SUCCESS_PRODUCT_DETAIL,
      data: res.product
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: FAIL_PRODUCT_DETAIL
    });
  }
}

export default function* watchFetchProduct() {
  yield takeLatest(REQUEST_FETCH_PRODUCT, fetchProduct);
  yield takeLatest(REQUEST_PRODUCT_DETAIL, productDetail);
}
