export const REQUEST_FETCH_SNAPLIST = 'REQUEST_FETCH_SNAPLISTLIST'
export const SUCCESS_FETCH_SNAPLIST = 'SUCCESS_FETCH_SNAPLIST'
export const FAIL_FETCH_SNAPLIST = 'FAIL_FETCH_SNAPLIST'

export const CHANGE_STATUS = 'CHANGE_STATUS'

export const getSnap = ({ history }) => ({
  type: REQUEST_FETCH_SNAPLIST,
  history,
})

export const changeStatus = (statusType, bool) => ({
  type: CHANGE_STATUS,
  statusType,
  bool,
})
