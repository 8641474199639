export const REQUEST_FORECAST = "REQUEST_FORECAST";
export const SUCCESS_FORECAST = "SUCCESS_FORECAST";
export const FAIL_FORECAST = "FAIL_FORECAST";
export const CLEAR_FORECAST = "CLEAR_FORECAST";

export const requestForecast = location => {
  return {
    type: REQUEST_FORECAST,
    payload: {
      location
    }
  };
};
