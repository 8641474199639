import styled, { css } from 'styled-components'
import { COLOR } from '@/config'

const Clearfix = css`
  &::after {
    display: block;
    content: "";
    clear: both;
  }
`

export const Wrap = styled.div`
  width: 100%;
  padding: 20px 0;
  padding-left: 30px;
  position: relative;
  user-select: none;
  cursor: default;

  ${Clearfix}
`

export const IconWrap = styled.div`
  float: left;
  width: 55px;
  height: 55px;
  position: relative;
  vertical-align: middle;
`

export const MsgWrap = styled.div`
  float: left;
  width: calc(100% - 110px);
  padding-left: 24px;
  box-sizing: border-box;
  vertical-align: middle;
`

const Text = styled.p`
  margin: 0;
  
  white-space: pre-wrap;
`

export const Title = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${props => props.type === 'error' ? COLOR.red_0 : props.type === 'info' ? '#777' : props.type === 'success' ? COLOR.green_0 : '#777'};
`

export const Msg = styled(Text)`
  margin-top: 6px;
  color: #777;
  font-size: 14px;
  line-height: 20px;
`

export const BtnWrap = styled.div`
  width: 42px;
  height: 42px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  
  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`