import Dropzone from "dropzone";
import Compressor from "compressorjs";

class Uploader {
  constructor(options) {
    try {
      const {
        id,
        url,
        method,
        acceptedFiles,
        parallelUploads,
        uploadMultiple,
        maxSize,
        clickable,
        maxFileCnt,
        multiUpload,
        autoProcessQueue,
        paramName,
        previewsContainer
      } = options;

      const uploader = new Dropzone(id, {
        url: url,
        method: method || "POST",
        //        withCredentials: true,  //이미지 처리 서버 관련 주석 처리
        headers: { "Cache-Control": null, "X-Requested-With": null },
        maxFiles: maxFileCnt ? maxFileCnt : multiUpload ? null : 1,
        parallelUploads: parallelUploads || 1,
        uploadMultiple: uploadMultiple || false,
        maxFilesize: maxSize || 10, // MB
        acceptedFiles: acceptedFiles || "image/*",
        paramName: paramName || "image", // The name that will be used to transfer the file
        previewsContainer: previewsContainer || false,
        dictFileTooBig: "파일크기 초과 (파일당 최대 {{maxFilesize}}MB)",
        dictMaxFilesExceeded: "최대 파일갯수 초과 (최대 {{maxFiles}}개)",
        dictInvalidFileType: "이미지 파일만 등록 가능합니다.",
        clickable: clickable || null,
        autoProcessQueue: autoProcessQueue === false ? false : true,
        transformFile: function(file, done) {
          new Compressor(file, {
            // quality: 0.8,
            success(result) {
              done(result);
            },
            error(err) {
              console.log(err.message);
            }
          });
        }
      });

      this.uploader = uploader;
    } catch (e) {
      console.error(
        `uploader를 생성중 에러가 발생했습니다.\nMESSAGE: ${e.message}`
      );
      console.log(
        `%c<< uploader options >>\n
options 타입 => object\n
{\n
    id: 버튼 || 박스 아이디 ex) #uploadBtn,   type = string\n
    url: APICALL URL,                     type = string'\n
    maxSize: 파일 최대 크기 (MB단위)        type = number\n
    multiUpload: true || false,           type = bool\n
      - true: 파일 여러개 전송가능\n
      - false: 파일 한개만 전송가능\n
}`,
        "font-size: 15px;"
      );
    }
  }

  init({
    onAddedfile,
    onSending,
    onProgress,
    onSuccess,
    onError,
    onComplete,
    onCompleteAll
  }) {
    const self = this;

    onAddedfile && this.uploader.on("addedfile", onAddedfile);
    onSending && this.uploader.on("sending", onSending); // args (file, xhr, formData)
    onProgress && this.uploader.on("uploadprogress", onProgress); // args (file, progress, bytesSent)
    onSuccess && this.uploader.on("success", onSuccess); //  args (file, res)
    onError && this.uploader.on("error", onError); // args (file, msg, res)

    this.uploader.on("complete", function(file) {
      self.uploader.removeFile(file);
      onComplete && onComplete(file);
    });

    onCompleteAll && this.uploader.on("queuecomplete", onCompleteAll);
  }

  removeFile(file) {
    return this.uploader.removeFile(file);
  }

  removeAllFiles(bool) {
    return this.uploader.removeAllFiles(bool);
  }

  processQueue() {
    this.uploader.processQueue();
  }

  getQueuedFiles() {
    return this.uploader.getQueuedFiles();
  }

  getUploadingFiles() {
    return this.uploader.getUploadingFiles();
  }

  getRejectedFiles() {
    return this.uploader.getRejectedFiles();
  }

  kill() {
    console.log("kill uploader");
    this.uploader.off();
    this.uploader.destroy();
  }
}

export default Uploader;
