const initialState = {
  seq: "", // 상품번호 (수정시)

  mainImg: "", // 메인 사진
  contact: "", // 연락처
  schedule: "", // 스케쥴
  intro: "", // 소개글

  // 영상 스타일 특징
  toneWhite: false, // 화이트
  toneMono: false, // 흑백
  toneGray: false, // 그레이톤
  toneVivid: false, // 선명한톤
  toneVintage: false, // 빈티지톤

  // 기본서비스지역
  standardRegions: [], // 0: 서울, 1: 인천광역시, 2: 경기남부(수원/용인/화성/안산/성남 등), 3: 경기북부(고양/구리/의정부  등), 4: 강원도, 5: 대전광역시, 6: 충청남도, 7: 충청북도, 8: 대구광역시, 9: 경상남도, 10: 경상북도, 11: 광주광역시, 12: 전라북도, 13: 전라남도, 14: 울산광역시, 15: 부산광역시, 16: 세종특별시, 17: 제주도
  additionalCostInfo: "", // 기본 서비스 지역 외 출장비

  option: "", // 추가 옵션
  event: "", // 자체 이벤트

  reviewImg: [], // 후기 인증샷 이미지
  albumImg: [], // 앨범 이미지
  etcImg: [], // 기타 이미지

  // 서비스 규정
  offerOriginalFileRule: "", // 이용 및 환불규정 - 원본영상파일제공
  offerCorrectionFileRule: "", // 이용 및 환불규정 - 보정영상파일제공

  // 기타 환불 규정
  periodStoreFileRule: "", // 기타 환불규정 - 파일 보관 기간
  offerTypeFileRule: "", // 기타 환불규정 - 파일 제공 방식
  refundUserRule: "", // 기타 환불규정 - 고객 측 환불 규정
  refundEnterpriseRule: "", // 기타 환불규정 - 업체 측 환불 규정

  details: [] // 상품 구성
};

export default {
  initialState
};
