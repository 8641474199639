import {
  REQUEST_TERMS,
  FAIL_TERMS,
  FETCH_TERMS,
  CHANGE_TERMS_SEQ
} from "@/Actions/terms/terms.actions";

const initialState = {
  list: [],
  selectedSeq: null,
  isLoading: true
};

const termsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_TERMS:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_TERMS:
      return {
        ...state,
        list: payload.data,
        isLoading: false
      };
    case FAIL_TERMS:
      return {
        ...state,
        isLoading: false
      };
    case CHANGE_TERMS_SEQ:
      return {
        ...state,
        selectedSeq: payload.seq
      };
    default:
      return state;
  }
};

export default termsReducer;
