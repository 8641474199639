const initialState = {
  seq: '',                // 상품번호 (수정시)
  isShow: false,           // 노출여부 (수정시)

  mainImg: '',            // 메인 사진
  contact: '',            // 연락처
  schedule: '',           // 스케쥴
  intro: '',              // 소개글

  // 촬영 상품 구성 특징
  applyAlbum: false,      // 앨범제공
  applyFrame: false,      // 액자제공
  selectable: false,      // 셀렉가능
  shootDuo: false,        // 2인 촬영
  shootMakeup: false,     // 메이크업샵 촬영

  // 촬영 스타일 특징
  toneSepia: false,       // 세피아톤
  toneWhite: false,       // 화이트톤
  tonePeach: false,       // 피치톤
  vivid: false,           // 선명한색감
  moodWarm: false,        // 따뜻한 분위기
  comfy: false,           // 편안한색감
  gorgeous: false,        // 화사한색감
  classic: false,         // 고전적인클래식
  dramatic: false,        // 드라마틱
  vintage: false,         // 빈티지보정

  // 기본서비스지역
  standardRegions: [],    // 0: 서울, 1: 인천광역시, 2: 경기남부(수원/용인/화성/안산/성남 등), 3: 경기북부(고양/구리/의정부  등), 4: 강원도, 5: 대전광역시, 6: 충청남도, 7: 충청북도, 8: 대구광역시, 9: 경상남도, 10: 경상북도, 11: 광주광역시, 12: 전라북도, 13: 전라남도, 14: 울산광역시, 15: 부산광역시, 16: 세종특별시, 17: 제주도
  additionalCostInfo: '', // 기본 서비스 지역 외 출장비

  option: '',             // 추가 옵션
  event: '',              // 자체 이벤트

  reviewImg: [],          // 후기 인증샷 이미지
  albumImg: [],           // 앨범 이미지
  etcImg: [],             // 기타 이미지

  // 서비스 규정
  offerOriginalFileRule: '',          // 원본파일 제공
  offerCorrectionFileRuleType: '',    // 보정파일 제공 - 0: 촬영 후, 1: 고객 셀렉 후
  offerCorrectionFileRule: '',        // 보정파일 제공
  offerPrintPictureRuleType: '',      // 앨범/액자/인화사진 제공 - 0: 촬영후, 1: 고객 셀렉 후
  offerPrintPictureRule: '',          // 앨범/액자/인화사진 제공

  // 기타 환불 규정
  periodStoreFileRule: '',            // 파일보관기간
  offerTypeFileRule: '',              // 파일 제공 방식
  refundUserRule: '',                 // 고객측 취소, 환불규정
  refundEnterpriseRule: '',           // 업체측 취소, 환불규정
  policyBadWeatherRule: '',           // 기상악화 시 운영방침

  details: [],             // 상품 구성
}

export default {
  initialState
}