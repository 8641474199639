import { call, put, takeLatest } from "redux-saga/effects";
import * as _ from "lodash";
import moment from "moment";
import { apiCall } from "@/Utils";

import {
  REQUEST_FORECAST,
  SUCCESS_FORECAST,
  FAIL_FORECAST
} from "@/Actions/weather/forecast";

const groupTodayData = (list, groupName, itemKey, itemKey2) =>
  _.chain(list)
    .groupBy(groupName)
    .map((value, keyValue) => ({
      time: keyValue,
      values: _.reduce(
        value,
        (result, item) => {
          result[item[itemKey]] = item[itemKey2];
          return result;
        },
        {}
      )
    }))
    .value();

const getNcstBase = () => {
  const nowTime = parseInt(moment().format("Hmm"), 10);
  let baseDate = moment().format("YYYYMMDD");
  let baseTime = null;

  if (nowTime < 40) {
    baseDate = moment()
      .subtract(1, "day")
      .format("YYYYMMDD");
    baseTime = "2300";
  } else if (nowTime < 140) {
    baseTime = "0000";
  } else if (nowTime < 240) {
    baseTime = "0100";
  } else if (nowTime < 340) {
    baseTime = "0200";
  } else if (nowTime < 440) {
    baseTime = "0300";
  } else if (nowTime < 540) {
    baseTime = "0400";
  } else if (nowTime < 640) {
    baseTime = "0500";
  } else if (nowTime < 740) {
    baseTime = "0600";
  } else if (nowTime < 840) {
    baseTime = "0700";
  } else if (nowTime < 940) {
    baseTime = "0800";
  } else if (nowTime < 1040) {
    baseTime = "0900";
  } else if (nowTime < 1140) {
    baseTime = "1000";
  } else if (nowTime < 1240) {
    baseTime = "1100";
  } else if (nowTime < 1340) {
    baseTime = "1200";
  } else if (nowTime < 1440) {
    baseTime = "1300";
  } else if (nowTime < 1540) {
    baseTime = "1400";
  } else if (nowTime < 1640) {
    baseTime = "1500";
  } else if (nowTime < 1740) {
    baseTime = "1600";
  } else if (nowTime < 1840) {
    baseTime = "1700";
  } else if (nowTime < 1940) {
    baseTime = "1800";
  } else if (nowTime < 2040) {
    baseTime = "1900";
  } else if (nowTime < 2140) {
    baseTime = "2000";
  } else if (nowTime < 2240) {
    baseTime = "2100";
  } else if (nowTime < 2340) {
    baseTime = "2200";
  }

  return {
    baseDate,
    baseTime
  };
};

const getFcstBase = () => {
  const nowTime = parseInt(moment().format("Hmm"), 10);
  let baseDate = moment().format("YYYYMMDD");
  let baseTime = null;

  if (nowTime < 45) {
    baseDate = moment()
      .subtract(1, "day")
      .format("YYYYMMDD");
    baseTime = "2330";
  } else if (nowTime < 145) {
    baseTime = "0030";
  } else if (nowTime < 245) {
    baseTime = "0130";
  } else if (nowTime < 345) {
    baseTime = "0230";
  } else if (nowTime < 445) {
    baseTime = "0330";
  } else if (nowTime < 545) {
    baseTime = "0430";
  } else if (nowTime < 645) {
    baseTime = "0530";
  } else if (nowTime < 745) {
    baseTime = "0630";
  } else if (nowTime < 845) {
    baseTime = "0730";
  } else if (nowTime < 945) {
    baseTime = "0830";
  } else if (nowTime < 1045) {
    baseTime = "0930";
  } else if (nowTime < 1145) {
    baseTime = "1030";
  } else if (nowTime < 1245) {
    baseTime = "1130";
  } else if (nowTime < 1345) {
    baseTime = "1230";
  } else if (nowTime < 1445) {
    baseTime = "1330";
  } else if (nowTime < 1545) {
    baseTime = "1430";
  } else if (nowTime < 1645) {
    baseTime = "1530";
  } else if (nowTime < 1745) {
    baseTime = "1630";
  } else if (nowTime < 1845) {
    baseTime = "1730";
  } else if (nowTime < 1945) {
    baseTime = "1830";
  } else if (nowTime < 2045) {
    baseTime = "1930";
  } else if (nowTime < 2145) {
    baseTime = "2030";
  } else if (nowTime < 2245) {
    baseTime = "2130";
  } else if (nowTime < 2345) {
    baseTime = "2230";
  }

  return {
    baseDate,
    baseTime
  };
};

const getHourlyBase = () => {
  const nowTime = parseInt(moment().format("Hmm"), 10);

  let baseDate = moment().format("YYYYMMDD");
  let baseTime = null;

  if (nowTime < 230) {
    baseDate = moment()
      .subtract(1, "day")
      .format("YYYYMMDD");
    baseTime = "2300";
  } else if (nowTime < 530) {
    baseTime = "0200";
  } else if (nowTime < 830) {
    baseTime = "0500";
  } else if (nowTime < 1130) {
    baseTime = "0800";
  } else if (nowTime < 1430) {
    baseTime = "1100";
  } else if (nowTime < 1730) {
    baseTime = "1400";
  } else if (nowTime < 2030) {
    baseTime = "1700";
  } else if (nowTime < 2330) {
    baseTime = "2000";
  }

  return {
    baseDate,
    baseTime
  };
};

const getWeeklyBase = () => {
  const nowTime = parseInt(moment().format("Hmm"), 10);
  let baseDate = moment().format("YYYYMMDD");
  let baseTime = null;

  if (nowTime < 600) {
    baseDate = moment()
      .subtract(1, "day")
      .format("YYYYMMDD");
    baseTime = "1800";
  } else if (nowTime < 1800) {
    baseTime = "0600";
  } else {
    baseTime = "1800";
  }

  return {
    baseDate,
    baseTime
  };
};

const formatUpdatedAt = (date, time) => {
  return moment(`${date} ${time}`, "YYYYMMDD HHmm").format(
    "YYYY.MM.DD(ddd) HH:mm"
  );
};

const getNcst = params => apiCall.get("/api/weather/ultra-short-ncst", params);
const getFcst = params => apiCall.get("/api/weather/ultra-short-fcst", params);
const getHourly = params => apiCall.get("/api/weather/town", params);
const getWeeklyTa = params => apiCall.get("/api/weather/midForecast", params);
const getWeeklyLand = params =>
  apiCall.get("/api/weather/midLandForecast", params);

function* requestForecast(action) {
  const { payload } = action;
  try {
    const ncstBase = getNcstBase();
    const ncstParams = {
      dataType: "JSON",
      pageNo: 1,
      numOfRows: 50,
      ...ncstBase,
      ...payload.location
    };
    const ncstData = yield call(getNcst, ncstParams);

    const fcstBase = getFcstBase();
    const fcstParams = {
      dataType: "JSON",
      pageNo: 1,
      numOfRows: 60,
      ...fcstBase,
      ...payload.location
    };
    const fcstData = yield call(getFcst, fcstParams);

    const ncst = groupTodayData(
      ncstData.response.body.items.item,
      "baseTime",
      "category",
      "obsrValue"
    );
    const fcst = groupTodayData(
      fcstData.response.body.items.item,
      "fcstTime",
      "category",
      "fcstValue"
    );

    // 시간별 날씨정보
    const hourlyBase = getHourlyBase();
    const hourlyData = yield call(getHourly, {
      dataType: "JSON",
      pageNo: 1,
      numOfRows: 225,
      ...hourlyBase,
      ...payload.location
    });
    const hourly = _.chain(hourlyData.response.body.items.item)
      .groupBy("category")
      .mapValues(data => {
        let selectedDate = null;
        return _.map(data, item => {
          if (!selectedDate || selectedDate !== item.fcstDate) {
            selectedDate = item.fcstDate;
            return item;
          }
          const { fcstDate, ...other } = item;
          return {
            ...other
          };
        });
      })
      .value();

    // 주간 날씨정보
    const weeklyBase = getWeeklyBase();
    const weeklyParams = {
      dataType: "JSON",
      pageNo: 1,
      numOfRows: 10,
      tmFc: `${weeklyBase.baseDate}${weeklyBase.baseTime}`
    };

    // 주간 날씨와  주간 강수 정보량
    const weeklyTaData = yield call(getWeeklyTa, {
      ...weeklyParams,
      regId: "11G00201"
    });
    const weeklyLandData = yield call(getWeeklyLand, {
      ...weeklyParams,
      regId: "11G00000"
    });
    const weeklyTa = weeklyTaData.response.body.items.item[0];
    const weeklyLand = weeklyLandData.response.body.items.item[0];

    yield put({
      type: SUCCESS_FORECAST,
      payload: {
        today: {
          data: [...ncst, ...fcst],
          updatedAt: formatUpdatedAt(ncstBase.baseDate, ncstBase.baseTime)
        },
        hourly: {
          data: hourly,
          updatedAt: formatUpdatedAt(hourlyBase.baseDate, hourlyBase.baseTime)
        },
        weekly: {
          data: [weeklyTa, weeklyLand],
          updatedAt: formatUpdatedAt(weeklyBase.baseDate, weeklyBase.baseTime)
        }
      }
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: FAIL_FORECAST
    });
  }
}

export default function* watchFetchForecast() {
  yield takeLatest(REQUEST_FORECAST, requestForecast);
}
