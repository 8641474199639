export const REQUEST_DVD_PORTFOLIO = "REQUEST_DVD_PORTFOLIO";
export const FAIL_DVD_PORTFOLIO = "FAIL_DVD_PORTFOLIO";
export const SUCCESS_DVD_PORTFOLIO = "SUCCESS_DVD_PORTFOLIO";

export const ADD_DVD_PORTFOLIO_FOLDER = "ADD_DVD_PORTFOLIO_FOLDER";
export const CHANGE_DVD_PORTFOLIO_STATE = "CHANGE_DVD_PORTFOLIO_STATE";

export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const requestDvdPortfolio = ({ type, productSeq }) => {
  return {
    type: REQUEST_DVD_PORTFOLIO,
    payload: {
      type,
      productSeq
    }
  };
};

export const failDvdPortfolio = () => {
  return {
    type: FAIL_DVD_PORTFOLIO
  };
};

export const successDvdPortfolio = ({ data }) => {
  return {
    type: SUCCESS_DVD_PORTFOLIO,
    payload: {
      data
    }
  };
};

export const addFolder = ({ folder }) => ({
  type: ADD_DVD_PORTFOLIO_FOLDER,
  payload: {
    folder
  }
});

export const changeState = data => ({
  type: CHANGE_DVD_PORTFOLIO_STATE,
  payload: data
});

export const setErrors = ({ name, errors }) => ({
  type: SET_ERRORS,
  payload: {
    name,
    errors
  }
});

export const clearErrors = () => ({
  type: CLEAR_ERRORS
});
