import {
  SET_INIT,
} from '@/Actions/common'

const initialState = {
  init: false,
}

const common = (state = initialState, action) => {
  switch (action.type) {
    case SET_INIT:
      return {
        ...state,
        init: action.init,
      }
    
    default:
      return state
  }
}

export default common