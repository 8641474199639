import {
  REQUEST_DASHBOARD_DATA,
  SUCCESS_DASHBOARD_DATA,
  FAIL_DASHBOARD_DATA,
  FETCH_DASHBOARD_DATA,
  CLEAR_DASHBOARD_DATA,
  REQUEST_DASHBOARD_TABS,
  SUCCESS_DASHBOARD_TABS,
  FETCH_DASHBOARD_TABS,
  FAIL_DASHBOARD_TABS,
  CLEAR_DASHBOARD_TABS
} from "../Actions/dashboard";

const initialState = {
  dashboardTabs: {
    list: [],
    isLoading: true
  },
  dashboardData: {
    data: null,
    isLoading: true
  }
};

const dashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          isLoading: true
        }
      };
    case SUCCESS_DASHBOARD_DATA:
    case FETCH_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: {
          data: payload.data,
          isLoading: false
        }
      };
    case FAIL_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          isLoading: false
        }
      };
    case CLEAR_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: {
          ...initialState.dashboardData
        }
      };

    case REQUEST_DASHBOARD_TABS:
      return {
        ...state,
        dashboardTabs: {
          ...state.dashboardTabs,
          isLoading: true
        }
      };
    case SUCCESS_DASHBOARD_TABS:
    case FETCH_DASHBOARD_TABS:
      return {
        ...state,
        dashboardTabs: {
          list: payload.tabs,
          isLoading: false
        },
        dashboardData: {
          ...state.dashboardData,
          isLoading: false
        }
      };
    case FAIL_DASHBOARD_TABS:
      return {
        ...state,
        dashboardTabs: {
          ...state.dashboardTabs,
          isLoading: false
        },
        dashboardData: {
          ...state.dashboardData,
          isLoading: false
        }
      };
    case CLEAR_DASHBOARD_TABS:
      return {
        ...state,
        dashboardTabs: {
          ...initialState.dashboardTabs
        }
      };
    default:
      return state;
  }
};

export default dashboardReducer;
