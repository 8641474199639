import apiCall from '@/Utils/apiCall'

const getList = type => apiCall.get(`/api/product/folder/${type}`)

const upload = ({ path, folders }) => apiCall.post(`/api/product/image/${path}`, {
  folders: folders
})

export default {
  getList,
  upload,
}