export const REQUEST_DASHBOARD_DATA = "REQUEST_DASHBOARD_DATA";
export const SUCCESS_DASHBOARD_DATA = "SUCCESS_DASHBOARD_DATA";
export const FAIL_DASHBOARD_DATA = "FAILD_DASHBOARD_DATA";
export const FETCH_DASHBOARD_DATA = "FETCH_DASHBOARD_DATA";
export const CLEAR_DASHBOARD_DATA = "CLEAR_DASHBOARD_DATA";

export const REQUEST_DASHBOARD_TABS = "REQUEST_DASHBOARD_TABS";
export const SUCCESS_DASHBOARD_TABS = "SUCCESS_DASHBOARD_TABS";
export const FAIL_DASHBOARD_TABS = "FAIL_DASHBOARD_TABS";
export const FETCH_DASHBOARD_TABS = "FETCH_DASHBOARD_TABS";
export const CLEAR_DASHBOARD_TABS = "CLEAR_DASHBOARD_TABS";

/* 대시보드 데이터 조회 Actions */
export const requestDashboardData = ({ type, periodFrom, periodTo }) => ({
  type: REQUEST_DASHBOARD_DATA,
  payload: {
    type,
    periodFrom,
    periodTo
  }
});

export const successDashboardData = data => ({
  type: SUCCESS_DASHBOARD_DATA,
  payload: {
    data
  }
});

export const fetchDashboardData = data => ({
  type: FETCH_DASHBOARD_DATA,
  payload: {
    data
  }
});

export const clearDashboardData = () => ({
  type: CLEAR_DASHBOARD_DATA
});

/* 멤버십 탭 Actions */
export const requestDashboardTabs = () => ({
  type: REQUEST_DASHBOARD_TABS
});

export const successDashboardTabs = tabs => ({
  type: SUCCESS_DASHBOARD_TABS,
  payload: {
    tabs
  }
});

export const fetchDashboardTabs = tabs => ({
  type: FETCH_DASHBOARD_TABS,
  payload: {
    tabs
  }
});

export const clearDashboardTabs = () => ({
  type: CLEAR_DASHBOARD_TABS
});
