import apiCall from "@/Utils/apiCall";

const getList = type => apiCall.get(`/api/product/dvd/folder/${type}`);

const upload = ({ folders }) =>
  apiCall.post(`/api/product/dvd/portfolio`, {
    folders
  });

export default {
  getList,
  upload
};
