import {
  REQUEST_FETCH_SNAPLIST,
  SUCCESS_FETCH_SNAPLIST,
  FAIL_FETCH_SNAPLIST,
  CHANGE_STATUS
} from "@/Actions/snapList";

const initialState = {
  jeju: {
    enterpriseId: "",
    mainImg: "", // (메인이미지명)
    membershipType: "", // (멤버십 타입 0: 일반멤버십, 1: 플러스 멤버십, 2: 퍼스트 멤버십, 3: 프리미엄 멤버십)
    membershipTypeStr: "", // (멤버십 명)
    isShow: false, // (상품 공개여부)
    isEdit: false, // (포트폴리오 등록여부 true(수정), false(등록) 으로 표기)
    type: "", // (타입 jeju:제주스냅, main : 본식스냅)
    productSeq: "", // (상품번호)
    status: "", // (상품 상태 0: 등록대기, 1:등록완료, 3: 검수반려, 4:재검수요청)
    returnReason: "", // 반려사유
    operatorName: "", //
    operatorPhone: "",
    periodFrom: "", //,
    periodTo: ""
  },
  main: {
    enterpriseId: "",
    mainImg: "", // (메인이미지명)
    membershipType: "", // (멤버십 타입 0: 일반멤버십, 1: 플러스 멤버십, 2: 퍼스트 멤버십, 3: 프리미엄 멤버십)
    membershipTypeStr: "", // (멤버십 명)
    isShow: false, // (상품 공개여부)
    isEdit: false, // (포트폴리오 등록여부 true(수정), false(등록) 으로 표기)
    type: "", // (타입 jeju:제주스냅, main : 본식스냅)
    productSeq: "", // (상품번호)
    status: "", // (상품 상태 0: 등록대기, 1:등록완료, 3: 검수반려, 4:재검수요청)
    returnReason: "", // 반려사유
    operatorName: "", //
    operatorPhone: "",
    periodFrom: "", //,
    periodTo: ""
  },
  simple: {
    enterpriseId: "",
    mainImg: "", // (메인이미지명)
    membershipType: "", // (멤버십 타입 0: 일반멤버십, 1: 플러스 멤버십, 2: 퍼스트 멤버십, 3: 프리미엄 멤버십)
    membershipTypeStr: "", // (멤버십 명)
    isShow: false, // (상품 공개여부)
    isEdit: false, // (포트폴리오 등록여부 true(수정), false(등록) 으로 표기)
    type: "", // (타입 jeju:제주스냅, main : 본식스냅)
    productSeq: "", // (상품번호)
    status: "", // (상품 상태 0: 등록대기, 1:등록완료, 3: 검수반려, 4:재검수요청)
    returnReason: "", // 반려사유
    operatorName: "", //
    operatorPhone: "",
    periodFrom: "", //,
    periodTo: ""
  },
  dme: {
    enterpriseId: "",
    mainImg: "", // (메인이미지명)
    membershipType: "", // (멤버십 타입 0: 일반멤버십, 1: 플러스 멤버십, 2: 퍼스트 멤버십, 3: 프리미엄 멤버십)
    membershipTypeStr: "", // (멤버십 명)
    isShow: false, // (상품 공개여부)
    isEdit: false, // (포트폴리오 등록여부 true(수정), false(등록) 으로 표기)
    type: "", // (타입 jeju:제주스냅, main : 본식스냅)
    productSeq: "", // (상품번호)
    status: "", // (상품 상태 0: 등록대기, 1:등록완료, 3: 검수반려, 4:재검수요청)
    returnReason: "", // 반려사유
    operatorName: "", //
    operatorPhone: "",
    periodFrom: "", //,
    periodTo: ""
  },
  dvd: {
    enterpriseId: "",
    mainImg: "", // (메인이미지명)
    membershipType: "", // (멤버십 타입 0: 일반멤버십, 1: 플러스 멤버십, 2: 퍼스트 멤버십, 3: 프리미엄 멤버십)
    membershipTypeStr: "", // (멤버십 명)
    isShow: false, // (상품 공개여부)
    isEdit: false, // (포트폴리오 등록여부 true(수정), false(등록) 으로 표기)
    type: "", // (타입 jeju:제주스냅, main : 본식스냅)
    productSeq: "", // (상품번호)
    status: "", // (상품 상태 0: 등록대기, 1:등록완료, 3: 검수반려, 4:재검수요청)
    returnReason: "", // 반려사유
    operatorName: "", //
    operatorPhone: "",
    periodFrom: "", //,
    periodTo: ""
  },
  remind: {
    enterpriseId: "",
    mainImg: "", // (메인이미지명)
    membershipType: "", // (멤버십 타입 0: 일반멤버십, 1: 플러스 멤버십, 2: 퍼스트 멤버십, 3: 프리미엄 멤버십)
    membershipTypeStr: "", // (멤버십 명)
    isShow: false, // (상품 공개여부)
    isEdit: false, // (포트폴리오 등록여부 true(수정), false(등록) 으로 표기)
    type: "remind", // (타입 jeju:제주스냅, main : 본식스냅)
    productSeq: "", // (상품번호)
    status: "", // (상품 상태 0: 등록대기, 1:등록완료, 3: 검수반려, 4:재검수요청)
    returnReason: "", // 반려사유
    operatorName: "", //
    operatorPhone: "",
    periodFrom: "", //,
    periodTo: ""
  },
  family: {
    enterpriseId: "",
    mainImg: "", // (메인이미지명)
    membershipType: "", // (멤버십 타입 0: 일반멤버십, 1: 플러스 멤버십, 2: 퍼스트 멤버십, 3: 프리미엄 멤버십)
    membershipTypeStr: "", // (멤버십 명)
    isShow: false, // (상품 공개여부)
    isEdit: false, // (포트폴리오 등록여부 true(수정), false(등록) 으로 표기)
    type: "family", // (타입 jeju:제주스냅, main : 본식스냅)
    productSeq: "", // (상품번호)
    status: "", // (상품 상태 0: 등록대기, 1:등록완료, 3: 검수반려, 4:재검수요청)
    returnReason: "", // 반려사유
    operatorName: "", //
    operatorPhone: "",
    periodFrom: "", //,
    periodTo: ""
  },
  isLoading: true
};

const snapList = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FETCH_SNAPLIST:
      return {
        ...state,
        isLoading: true
      };

    case SUCCESS_FETCH_SNAPLIST:
      return {
        ...state,
        jeju: action.jeju,
        main: action.main,
        simple: action.simple,
        dme: action.dme,
        dvd: action.dvd,
        remind: action.remind,
        family: action.family,
        isLoading: false
      };

    case FAIL_FETCH_SNAPLIST:
      return {
        ...state,
        isLoading: false
      };

    case CHANGE_STATUS:
      return {
        ...state,
        [action.statusType]: {
          ...state[action.statusType],
          isShow: action.bool
        }
      };

    default:
      return state;
  }
};

export default snapList;
