import React from 'react'

import withController from './Controller'
import Icon from './Icon'

import { 
  Wrap, IconWrap, 
  MsgWrap, Title, Msg, BtnWrap } from './styled'

const Message = ({
  data, // props
  isHover, // state
  removeSelf, mouseEnter, mouseLeave, // func
}) => {
  return (
    <Wrap onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      <IconWrap>
        <Icon name={data.type} />
      </IconWrap>
      <MsgWrap>
        <Title type={data.type}>{data.title}</Title>
        <Msg>{data.message}</Msg>
      </MsgWrap>
      <BtnWrap onClick={removeSelf}>
        <Icon name="x" width="15" height="15" />
      </BtnWrap>
    </Wrap>
  )
}

export default withController(Message)