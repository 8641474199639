import {
  REQUEST_RADAR_IMAGES,
  SUCCESS_RADAR_IMAGES,
  FAIL_RADAR_IMAGES,
  CLEAR_RADAR_IMAGES
} from "@/Actions/weather/radar";

const initialState = {
  isLoading: true,
  images: null
};

const radarReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_RADAR_IMAGES:
      return {
        ...state,
        isLoading: true
      };
    case SUCCESS_RADAR_IMAGES:
      return {
        ...state,
        images: action.payload.images,
        isLoading: false
      };
    case FAIL_RADAR_IMAGES:
      return {
        ...state,
        isLoading: false
      };
    case CLEAR_RADAR_IMAGES:
      return initialState;
    default:
      return state;
  }
};
export default radarReducer;
