const initialState = {
  seq: "", // 상품번호 (수정시)
  isShow: false, // 노출여부 (수정시)

  contact: "", // 연락처
  mainImg: "", // 메인 사진
  schedule: "", // 스케쥴
  intro: "", // 소개글

  // 촬영 지역
  shootType: "",
  shootState: "",
  shootCity: "",

  //사진특징
  hanbok: false, //한복사진
  monochrome: false, //흑백사진
  vintage: false, //빈티지
  comfy: false, //편안한
  vivid: false, //선명한
  romantic: false, //로맨틱
  clean: false, //깨끗한
  simple: false, //심플한
  trendy: false, //트렌디
  lovely: false, //러블리
  pictorial: false, //화보형
  classic: false, //클래식
  dramatic: false, //드라마틱
  warm: false, //따뜻한
  special: false, //특별한
  additionalCostInfo: "", //지역별 출장비

  // 추가 정보
  option: "", // 추가 옵션
  collaboDressMakeupInfo: "", //제휴업체 - 헤어/메이크업샵
  collaboPropsInfo: "", //제휴업체 - 스타일링(소품/의상) 업체

  event: "", // 자체 이벤트
  reviewImg: [], // 후기 인증샷 이미지
  albumImg: [], // 앨범 이미지
  etcImg: [], // 기타 이미지

  // 서비스 규정
  offerOriginalFileRule: "", // 원본파일 제공
  offerCorrectionFileRuleType: "", // 보정파일 제공 - 0: 촬영 후, 1: 고객 셀렉 후
  offerCorrectionFileRule: "", // 보정파일 제공
  offerPrintPictureRuleType: "", // 앨범/액자/인화사진 제공 - 0: 촬영후, 1: 고객 셀렉 후
  offerPrintPictureRule: "", // 앨범/액자/인화사진 제공

  // 기타 환불 규정
  periodStoreFileRule: "", // 파일보관기간
  offerTypeFileRule: "", // 파일 제공 방식
  refundUserRule: "", // 고객측 취소, 환불규정
  refundEnterpriseRule: "", // 업체측 취소, 환불규정

  details: [] // 상품 구성
};

export default {
  initialState
};
