import React from 'react';
import PropTypes from 'prop-types';

import { Wrap } from './styled';

const Container = ({ options }) => (
  <Wrap
    id={options.containerId}
    type={options.type}
    colors={options.colors}
    position={options.position} />
)

Container.propTypes = {
  options: PropTypes.object,
}

export default Container;