import apiCall from "@/Utils/apiCall";

const send = ({ method, type, data }) =>
  apiCall[method](`/api/product/snap/${type}`, data);

// 기존 snap 상품과 구조적으로 분리되어야 맞는 것 같음.
const dvdSend = ({ method, type, data }) =>
  apiCall[method](`/api/product/${type}/main`, data);

const detail = data => apiCall.get("/api/product/detail", data);

export default {
  send,
  dvdSend,
  detail
};
