export const REQUEST_FETCH_PORTFOLIO = "REQUEST_FETCH_PORTFOLIO";
export const SUCCESS_FETCH_PORTFOLIO = "SUCCESS_FETCH_PORTFOLIO";
export const FAIL_FATCH_PORTFOLIO = "FAIL_FATCH_PORTFOLIO";
export const CLEAR_PORTFOLIO = "CLEAR_PORTFOLIO";

export const CHANGE_PORTFOLIO_STATE = "CHANGE_PORTFOLIO_STATE";
export const ADD_PORTFOLIO_FOLDER = "ADD_PORTFOLIO_FOLDER";

export const REQUEST_UPLOAD_PORTFOLIO = "REQUEST_UPLOAD_PORTFOLIO";
export const SUCCESS_UPLOAD_PORTFOLIO = "SUCCESS_UPLOAD_PORTFOLIO";
export const FAIL_UPLOAD_PORTFOLIO = "FAIL_UPLOAD_PORTFOLIO";

export const getPortfolio = portfolioType => ({
  type: REQUEST_FETCH_PORTFOLIO,
  portfolioType
});

export const changeState = data => ({
  type: CHANGE_PORTFOLIO_STATE,
  data
});

export const addFolder = folder => ({
  type: ADD_PORTFOLIO_FOLDER,
  folder
});

export const clearPortfolio = () => ({
  type: CLEAR_PORTFOLIO
});

export const fetchPortfolio = data => ({
  type: REQUEST_UPLOAD_PORTFOLIO,
  ...data
});
