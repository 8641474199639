import { call, put, takeLatest } from "redux-saga/effects";
import { find } from "lodash";

import {
  REQUEST_FETCH_PORTFOLIO,
  SUCCESS_FETCH_PORTFOLIO,
  FAIL_FATCH_PORTFOLIO,
  REQUEST_UPLOAD_PORTFOLIO,
  SUCCESS_UPLOAD_PORTFOLIO,
  FAIL_UPLOAD_PORTFOLIO
} from "@/Actions/portfolio";

import { portfolio } from "./api";
import Toast from "@/Components/Toast";

const convertNull = data =>
  data.reduce((r, i) => {
    i.chapel = i.chapel ? true : false;
    i.outdoor = i.outdoor ? true : false;
    i.convention = i.convention ? true : false;
    i.hotel = i.hotel ? true : false;
    i.house = i.house ? true : false;
    i.traditional = i.traditional ? true : false;
    i.images = i.images ? convertImg(i.images) : [];
    i.jibunAddress = i.jibunAddress ? i.jibunAddress : "";
    i.roadAddress = i.roadAddress ? i.roadAddress : "";
    i.folderName = i.folderName ? i.folderName : "";

    r.push(i);
    return r;
  }, []);

const convertImg = images =>
  images.reduce((r, i) => {
    if (i === null) return r;
    const temp = {
      name: i
    };
    r.push(temp);
    return r;
  }, []);

function* fetchPortfolio(action) {
  try {
    const res = yield call(portfolio.getList, action.portfolioType);
    const temp = convertNull(res);
    const mainImg = find(temp, i => i.isRepresent);

    const data = temp.reduce((result, item) => {
      if (item.isRepresent) return result;

      result.push(item);
      return result;
    }, []);

    yield put({
      type: SUCCESS_FETCH_PORTFOLIO,
      mainImg,
      data
    });
  } catch (err) {
    console.log(err);
    let msg = "";
    try {
      msg = err.msg;
    } catch (err) {
      msg = "서버에러가 발생하였습니다.";
    }
    Toast.error(msg);

    yield put({
      type: FAIL_FATCH_PORTFOLIO
    });
  }
}

function* uploadPortfolio(action) {
  try {
    yield call(portfolio.upload, {
      path: action.portfolioType,
      folders: action.folders
    });

    Toast.success("포트폴리오를 등록하였습니다.");
    action.history.push("/product/list");

    yield put({
      type: SUCCESS_UPLOAD_PORTFOLIO
    });
  } catch (err) {
    console.log(err);
    let msg = "";
    try {
      msg = err.msg;
    } catch (err) {
      msg = "서버에러가 발생하였습니다.";
    }
    Toast.error(msg);

    yield put({
      type: FAIL_UPLOAD_PORTFOLIO
    });
  }
}

export default function* watchFetchPortfolio() {
  yield takeLatest(REQUEST_FETCH_PORTFOLIO, fetchPortfolio);
  yield takeLatest(REQUEST_UPLOAD_PORTFOLIO, uploadPortfolio);
}
