import { call, put, takeLatest } from "redux-saga/effects";

import {
  REQUEST_DASHBOARD_DATA,
  FAIL_DASHBOARD_DATA,
  successDashboardData,
  REQUEST_DASHBOARD_TABS,
  FAIL_DASHBOARD_TABS,
  successDashboardTabs
} from "@/Actions/dashboard";

import { dashboard } from "./api";
import Toast from "@/Components/Toast";

function* fetchDashboardTabs() {
  try {
    const data = yield call(dashboard.getTabs);
    yield put(successDashboardTabs(data));
  } catch (err) {
    console.log(err);
    let msg = "";
    try {
      msg = err.msg;
    } catch (err) {
      msg = "서버에러가 발생하였습니다.";
    }
    Toast.error(msg);

    yield put({
      type: FAIL_DASHBOARD_TABS
    });
  }
}

function* fetchDashboardData(action) {
  const {
    payload: { type, periodFrom, periodTo }
  } = action;

  try {
    const data = yield call(dashboard.getData, {
      type,
      periodFrom,
      periodTo
    });
    yield put(successDashboardData(data));
  } catch (err) {
    console.log(err);
    let msg = "";
    try {
      msg = err.msg;
    } catch (err) {
      msg = "서버에러가 발생하였습니다.";
    }
    Toast.error(msg);

    yield put({
      type: FAIL_DASHBOARD_DATA
    });
  }
}

export default function* watchFetchDashboard() {
  yield takeLatest(REQUEST_DASHBOARD_TABS, fetchDashboardTabs);
  yield takeLatest(REQUEST_DASHBOARD_DATA, fetchDashboardData);
}
