// 모바일 에이전트 구분
export default {
  Android() {
    return navigator.userAgent.match(/Android/i) === null ? false : true;
  },
  BlackBerry() {
    return navigator.userAgent.match(/BlackBerry/i) === null ? false : true;
  },
  IOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i) === null
      ? false
      : true;
  },
  Opera() {
    return navigator.userAgent.match(/Opera Mini/i) === null ? false : true;
  },
  Windows() {
    return navigator.userAgent.match(/IEMobile/i) === null ? false : true;
  },
  isTablet() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent
      ) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
    return isTablet;
  },
  any() {
    return (
      this.Android() ||
      this.BlackBerry() ||
      this.IOS() ||
      this.Opera() ||
      this.Windows() ||
      this.isTablet()
    );
  }
};
