import React from 'react';
import styled, { css } from 'styled-components';

import Rect from './Components/Rect';
import Circle from './Components/Circle';

const Wrapper = styled.div.attrs({
  className: 'spinner-wrapper'
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  ${({ overlay }) => overlay && css`
    position: fixed;
    background: rgba(255, 255, 255, 0.5);
  `}

  z-index: 20;
`;


const LoadingIcon = ({ type, ...props }) => {
  if (!type || type === 'circle') {
    return <Circle {...props} />
  } else if (type === 'rect') {
    return <Rect {...props} />
  }
};

const Spinner = ({type, width, height, color, ...props}) => {
  return (
    <Wrapper {...props}>
      <LoadingIcon type={type} width={width} height={height} color={color} />
    </Wrapper>
  )
}

export default Spinner;