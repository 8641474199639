import { isMobile } from "../config";

const defaultOption = {
  // 삭제 예정
  colors: {
    brand: {
      red: ["#DD4C5C"],
      "red-darken": ["#B53B49"],
      beige: ["#C29778"],
      "beige-light": ["#FFFDFC"]
    },
    grayscale: [
      "#333",
      "#6F6F6F",
      "#A3A3A3",
      "#BDBDBD",
      "#CCCCCC",
      "#EDEDED",
      "#F8F8F8"
    ],
    sub: {
      red: ["#F03C3C"],
      blue: ["#5099FD"],
      violet: ["#A670D5"],
      orange: ["#F76B2C"],
      green: ["#53CB8C"]
    },
    white: ["#fff"],
    black: ["#000"]
  },

  // colors -> palette 변경
  palette: {
    brand: {
      red: ["#DD4C5C"],
      "red-darken": ["#B53B49"],
      beige: ["#C29778"],
      "beige-light": ["#FFFDFC"]
    },
    grayscale: [
      "#333",
      "#6F6F6F",
      "#A3A3A3",
      "#BDBDBD",
      "#CCCCCC",
      "#EDEDED",
      "#F8F8F8"
    ],
    sub: {
      red: ["#F03C3C"],
      blue: ["#5099FD"],
      violet: ["#A670D5"],
      orange: ["#F76B2C"],
      green: ["#53CB8C"]
    },
    white: ["#fff"],
    black: ["#000"]
  },
  fonts: ["Spoqa Han Sans", "sans-serif"],
  fontWeight: {
    light: 300,
    normal: 400,
    bold: 800
  },
  limit: {
    minWidth: 0,
    maxWidth: 0
  }
};

const deviceOption = {};
deviceOption.desktop = {
  fontSize: {
    xl: "36px",
    large: "28px",
    midium: "19px",
    small: "17px",
    xs: "14px",
    "2xs": "12px"
  },
  lineHeight: {
    xl: "1.5",
    large: "1.464",
    midium: "1.473",
    small: "1.47",
    xs: "1.429",
    "2xs": "1.5"
  },
  limit: {
    maxWidth: "1200px",
    minWidth: 0
  }
};

deviceOption.tablet = {
  fontSize: {
    xl: "48px",
    large: "36px",
    midium: "28px",
    xs: "24px",
    "2xs": "20px"
  },

  // 임시
  lineHeight: {
    xl: "1.5",
    large: "1.464",
    midium: "1.473",
    xs: "1.429",
    "2xs": "1.5"
  },
  // 임시
  limit: {
    maxWidth: 1200,
    minWidth: 0
  }
};

deviceOption.mobile = {
  fontSize: {
    xl: "24px",
    large: "18px",
    midium: "14px",
    small: "12px",
    xs: "11px",
    "2xs": "10px"
  },

  lineHeight: {
    xl: "1.5",
    large: "1.5",
    midium: "1.473",
    small: "1.5",
    xs: "1.454", // 임시
    "2xs": "1.5"
  },
  // 임시
  limit: {
    maxWidth: 1200,
    minWidth: 0
  }
};

const currentDevice = isMobile ? "mobile" : "desktop";

export const deviceTheme = device => {
  return {
    ...defaultOption,
    ...deviceOption[device]
  };
};

const theme = deviceTheme(currentDevice);

export default theme;
