import { call, put, takeLatest } from "redux-saga/effects";
import { apiCall } from "@/Utils";
import moment from "moment";

import {
  REQUEST_RADAR_IMAGES,
  SUCCESS_RADAR_IMAGES,
  FAIL_RADAR_IMAGES
} from "@/Actions/weather/radar";

const getRadar = params => apiCall.get("/api/weather/radar", params);
function* requestRadarImages() {
  try {
    const data = yield call(getRadar, {
      data: "CMP_WRC",
      dataType: "JSON",
      numOfRows: 1,
      time: moment().format("YYYYMMDD"),
      pageNo: 1
    });

    const images = data.response.body.items.item[0]["rdr-img-file"]
      .replace(/[\[\]]+/g, "")
      .trim()
      .split(", ");

    yield put({
      type: SUCCESS_RADAR_IMAGES,
      payload: {
        images
      }
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: FAIL_RADAR_IMAGES
    });
  }
}

export default function* watchFetchRadar() {
  yield takeLatest(REQUEST_RADAR_IMAGES, requestRadarImages);
}
