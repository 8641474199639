import React from 'react';

import { Loading } from './styled';

const Rect = ({width, height, color}) => {
  return(
    <Loading width={width} height={height} color={color}>
      <div />
      <div />
      <div />
      <div />
      <div />
    </Loading>
  )
}

export default Rect;