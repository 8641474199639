import "react-app-polyfill/ie9";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";

import configureStore from "@/Store/configureStore";
import rootSaga from "@/Sagas";

import App from "./App";
import "simplebar/dist/simplebar.min.css";

const store = configureStore();
store.runSaga(rootSaga);

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </CookiesProvider>,
  document.getElementById("root")
);
