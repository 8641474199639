import moment from 'moment';

const generateUUID = () => {
  let d = new Date().getTime();
  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = (d + Math.random()*16)%16 | 0;
      d = Math.floor(d/16);
      return (c === 'x' ? r : (r & (0x3 | 0x8))).toString(16);
  });
  return uuid;
}

function Timer(callback, delay) {
  let timerId;
  let start;
  let remaining = delay;

  this.stop = () => {
    window.clearTimeout(timerId);
  }
  
  this.pause = () => {
    let now = moment();
    window.clearTimeout(timerId);
    const timeleft = moment(now).diff(start);
    remaining -= timeleft;
    start = now;
  };

  this.start = () => {
    start = moment();
    window.clearTimeout(timerId);
    timerId = window.setTimeout(callback, Math.round(remaining / 1000) * 1000);
  };

  this.start();
}

export { generateUUID, Timer };