export const REQUEST_SATELLITE_IMAGES = "REQUEST_SATELLITE_IMAGES";
export const SUCCESS_SATELLITE_IMAGES = "SUCCESS_SATELLITE_IMAGES";
export const FAIL_SATELLITE_IMAGES = "FAIL_SATELLITE_IMAGES";
export const CLEAR_SATELLITE_IMAGES = "CLEAR_SATELLITE_IMAGES";

export const requestSatelliteImages = () => {
  return {
    type: REQUEST_SATELLITE_IMAGES
  };
};

export const clearSatelliteImages = () => {
  return {
    type: CLEAR_SATELLITE_IMAGES
  };
};
