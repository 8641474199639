export const REQUEST_FETCH_USER_INFO = 'REQUEST_FETCH_USER_INFO'
export const SUCCESS_FETCH_USER_INFO = 'SUCCESS_FETCH_USER_INFO'
export const FAIL_FETCH_USER_INFO = 'FAIL_FETCH_USER_INFO'

export const REQUEST_FETCH_LOGIN = 'REQUEST_FETCH_LOGIN'
export const SUCCESS_FETCH_LOGIN = 'SUCCESS_FETCH_LOGIN'
export const FAIL_FETCH_LOGIN = 'FAIL_FETCH_LOGIN'

export const REQUEST_FETCH_LOGOUT = 'REQUEST_FETCH_LOGOUT'
export const SUCCESS_FETCH_LOGOUT = 'SUCCESS_FETCH_LOGOUT'
export const CHANGE_USER_INFO = 'CHANGE_USER_INFO'

export const login = (email, password) => ({
  type: REQUEST_FETCH_LOGIN,
  email,
  password,
})

export const fetchUserInfo = () => ({
  type: REQUEST_FETCH_USER_INFO
})

export const changeUserInfo = () => ({
  type: CHANGE_USER_INFO
})

export const logout = () => ({
  type: REQUEST_FETCH_LOGOUT,
})
