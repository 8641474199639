import React, { Component } from 'react'

// const { gtag } = window

const withTracker = RouteComponent => (root = '') => class extends Component {
  componentDidMount = () => {
    const { location } = this.props
    this.trackPage(location.pathname)
  }

  trackPage = (page) => {
    // let path = page

    // if (root) {
    //   path = root + page
    // }

    // gtag('config', 'UA-128048020-2', {
    //   'page_location': window.location.href,
    //   'page_path': path,
    // })

    window.scrollTo(0, 0);
  };

  render() {
    return <RouteComponent {...this.props} />
  }
}

export default withTracker