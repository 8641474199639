import { keyframes } from 'styled-components';

export const BounceInRight = keyframes`
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  50% {
    transform: translate3d(-25px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
`

export const BounceInLeft = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  50% {
    transform: translate3d(25px, 0, 0);
  }
  
  to {
    opacity: 1;
    transform: none;
  }
`

export const BounceInUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  50% {
    transform: translate3d(0, -20px, 0);
  }
  
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

export const BounceInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  50% {
    transform: translate3d(0, 25px, 0);
  }
  
  to {
    opacity: 1;
    transform: none;
  }
`