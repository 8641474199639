import apiCall from "@/Utils/apiCall";

const getTabs = () => apiCall.get("/api/dashboard/tab");
const getData = ({ type, periodFrom, periodTo }) =>
  apiCall.get(`/api/dashboard`, { type, periodFrom, periodTo });

export default {
  getTabs,
  getData
};
