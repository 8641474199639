import {
  REQUEST_FETCH_PORTFOLIO,
  SUCCESS_FETCH_PORTFOLIO,
  FAIL_FATCH_PORTFOLIO,
  CHANGE_PORTFOLIO_STATE,
  ADD_PORTFOLIO_FOLDER,
  REQUEST_UPLOAD_PORTFOLIO,
  SUCCESS_UPLOAD_PORTFOLIO,
  FAIL_UPLOAD_PORTFOLIO,
  CLEAR_PORTFOLIO
} from "@/Actions/portfolio";

const initialState = {
  mainImg: {
    folderId: "",
    images: []
  },
  folders: [],
  isLoading: false
};

const portfolio = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_UPLOAD_PORTFOLIO:
    case REQUEST_FETCH_PORTFOLIO:
      return {
        ...state,
        isLoading: true
      };

    case SUCCESS_UPLOAD_PORTFOLIO:
      return initialState;

    case SUCCESS_FETCH_PORTFOLIO:
      return {
        ...state,
        mainImg: action.mainImg,
        folders: action.data,
        isLoading: false
      };

    case FAIL_UPLOAD_PORTFOLIO:
    case FAIL_FATCH_PORTFOLIO:
      return {
        ...state,
        isLoading: false
      };

    case CHANGE_PORTFOLIO_STATE:
      return {
        ...state,
        ...action.data
      };

    case ADD_PORTFOLIO_FOLDER:
      return {
        ...state,
        folders: [action.folder, ...state.folders]
      };
    case CLEAR_PORTFOLIO:
      return initialState;
    default:
      return state;
  }
};

export default portfolio;
