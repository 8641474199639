import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall } from "@/Utils";

import {
  REQUEST_TERMS,
  successTerms,
  failTerms,
  fetchTerms,
  changeTermsHistory
} from "@/Actions/terms/terms.actions";

const getTermsApi = params => apiCall.get("/api/term/history", params);

function* fetchTermsData(action) {
  try {
    const { payload } = action;
    const data = yield call(getTermsApi, {
      termSeq: payload.termSeq
    });

    yield put(successTerms());
    yield put(fetchTerms({ data }));
    yield put(changeTermsHistory({ seq: data[0].termsHistorySeq }));
  } catch (err) {
    console.log(err);
    yield put(failTerms());
  }
}

export default function* watchFetchTerms() {
  yield takeLatest(REQUEST_TERMS, fetchTermsData);
}
