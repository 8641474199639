const initialState = {
  seq: '', // 상품번호 (수정시)

  mainImg: '', // 메인 사진
  contact: '', // 연락처
  intro: '', // 소개글

  // 주요 서비스 항목
  isDress: false, // 드레스
  isMakeup: false, // 메이크업
  isHair: false, // 헤어
  hanbok: false, // 한복

  // 세부 서비스 항목
  isWeddingDress: false, // 웨딩드레스
  isVintageDress: false, // 빈티지드레스
  isSuit: false, // 수트
  isHanbok: false, // 한복
  isProps: false, // 소품
  isHelper: false, // 헬퍼
  isMakeupBride: false, // 신부메이크업
  isHairBride: false, // 신부헤어
  isMakeupGroom: false, // 신랑메이크업
  isHairGroom: false, // 신랑헤어

  // 추가 옵션
  option: '',

  event: '', // 자체 이벤트

  reviewImg: [], // 후기 인증샷 이미지

  // 서비스 규정
  refundEnterpriseRule: '', // 서비스 이용 및 환불 규정

  details: [] // 상품 구성
};

export default {
  initialState
};
