export const REQUEST_RADAR_IMAGES = "REQUEST_RADAR_IMAGES";
export const SUCCESS_RADAR_IMAGES = "SUCCESS_RADAR_IMAGES";
export const FAIL_RADAR_IMAGES = "FAIL_RADAR_IMAGES";
export const CLEAR_RADAR_IMAGES = "CLEAR_RADAR_IMAGES";

export const requestRadarImages = () => {
  return {
    type: REQUEST_RADAR_IMAGES
  };
};

export const clearRadarImages = () => {
  return {
    type: CLEAR_RADAR_IMAGES
  };
};
