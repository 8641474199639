import React, { Ref } from "react";

import CirclePath, { CirclePathProps } from "./CirclePath";
import CircleDot, { CircleDotProps } from "./CircleDot";

import styled, { css } from "styled-components";
import { withProp } from "styled-tools";

const wrapperBaseStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const wrapperPositionStyle = css`
  ${withProp("position", position => {
    if (position === "absolute") {
      return css`
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      `;
    }
  })};
`;
const Inner = styled.div`
  text-align: center;
`;
const Wrapper = styled.div`
  ${wrapperBaseStyle};
  ${wrapperPositionStyle};
`;

export type SpinnerTypeProps = CirclePathProps | CircleDotProps;

export type SpinnerProps = {
  innerRef?: Ref<any>;
  type?: "default" | "circle";
  position?: "absolute";
} & SpinnerTypeProps;

const Spinner: React.FC<SpinnerProps> = ({ innerRef, size, ...props }) => {
  if (props.type === "circle") {
    return (
      <Wrapper ref={innerRef} {...props}>
        <Inner>
          <CircleDot size={size} />
          {props.children}
        </Inner>
      </Wrapper>
    );
  }
  return (
    <Wrapper ref={innerRef} {...props}>
      <Inner>
        <CirclePath size={size} />
        {props.children}
      </Inner>
    </Wrapper>
  );
};
React.forwardRef((props, ref) => <Spinner {...props} innerRef={ref} />);

export default Spinner;
