import checkMobile from "./Utils/isMobile";

export const isMobile = checkMobile.any();
export const platform = isMobile ? "mobile" : "desktop";

export const MIN_WIDTH = 1024;
// 이미지 경로
const DEV_IMG_URL = "https://s3.ap-northeast-2.amazonaws.com/fdding-dev-image";
const PROD_IMG_URL =
  "https://s3.ap-northeast-2.amazonaws.com/fdding-prod-image";
export const IMG_URL =
  process.env.NODE_ENV === "development" ? DEV_IMG_URL : PROD_IMG_URL;

const DEV_ETC_IMG_URL =
  "https://s3.ap-northeast-2.amazonaws.com/fdding-dev-etc-image";
const PROD_ETC_IMG_URL =
  "https://s3.ap-northeast-2.amazonaws.com/fdding-prod-etc-image";
export const ETC_IMG_URL =
  process.env.NODE_ENV === "development" ? DEV_ETC_IMG_URL : PROD_ETC_IMG_URL;

const DEV_BUCKET = "fdding-dev-image";
const PROD_BUCKET = "fdding-prod-image";
export const IMG_BUCKET =
  process.env.NODE_ENV === "development" ? DEV_BUCKET : PROD_BUCKET;

const DEV_ETC_BUCKET = "fdding-dev-etc-image";
const PROD_ETC_BUCKET = "fdding-prod-etc-image";
export const ETC_IMG_BUCKET =
  process.env.NODE_ENV === "development" ? DEV_ETC_BUCKET : PROD_ETC_BUCKET;

// 이미지 path
export const IMG_PATH = [
  "logo",
  "business",
  "etc",
  "album",
  "jeju",
  "main",
  "simple",
  "dme"
];

// 상품 타입
export const TYPES = [
  "main",
  "jeju",
  "simple",
  "dme",
  "dvd",
  "remind",
  "family"
];

// 작가유형
export const PHOTOGRAPHER = [
  { key: 0, name: "대표" },
  { key: 1, name: "수석실장" },
  { key: 2, name: "실장" }
];

// 지역
export const LOCATION = [
  {
    key: 0,
    name: "서울특별시"
  },
  {
    key: 1,
    name: "인천광역시"
  },
  {
    key: 2,
    name: "경기남부",
    tail: "(수원/용인/화성/안산/성남 등)"
  },
  {
    key: 3,
    name: "경기북부",
    tail: "(고양/구리/의정부 등)"
  },
  {
    key: 15,
    name: "부산광역시"
  },
  {
    key: 8,
    name: "대구광역시"
  },
  {
    key: 5,
    name: "대전광역시"
  },
  {
    key: 11,
    name: "광주광역시"
  },
  {
    key: 16,
    name: "세종특별시"
  },
  {
    key: 14,
    name: "울산광역시"
  },
  {
    key: 4,
    name: "강원도"
  },
  {
    key: 7,
    name: "충청북도"
  },
  {
    key: 6,
    name: "충청남도"
  },
  {
    key: 10,
    name: "경상북도"
  },
  {
    key: 9,
    name: "경상남도"
  },
  {
    key: 12,
    name: "전라북도"
  },
  {
    key: 13,
    name: "전라남도"
  },
  {
    key: 17,
    name: "제주도"
  }
];

// 색상값
export const COLOR = {
  gray_6: "#f8f8f8",
  gray_5: "#ededed",
  gray_4: "#ccc",
  gray_3: "#bdbdbd",
  gray_2: "#a3a3a3",
  gray_1: "#6f6f6f",
  gray_0: "#333",
  beige_1: "#fffdfc",
  beige_0: "#c29778",
  blue_0: "#5099fd",
  violet_0: "#a670d5",
  orange_0: "#f76b2c",
  red_0: "#f03c3c",
  green_0: "#53cb8c",
  white_0: "#fff",
  black_0: "#000"
};
