import React from 'react';
import Spinner from '@/Components/Spinner';

class PageLoading extends React.Component {
  state = {
    headerHeight: 0
  };

  componentDidMount() {
    const header = document.querySelectorAll('header')[0];
    if (header) {
      const headerHeight = header.clientHeight;
      this.setState({
        headerHeight 
      });
    }
  }

  render() {
    const { headerHeight } = this.state;
    return(
      <div style={{
        position: 'fixed',
        top: headerHeight+'px',
        left: 0,
        bottom: 0,
        width: '100%',
        background: '#fff',
        zIndex: 10
      }}>
        <Spinner type="rect" />
      </div>
    )
  }
}

export default getComponent => class AsyncComponent extends React.Component {
  static Component = null;

  state = { Component: AsyncComponent.Component };

  componentDidMount() {
    this._isMount = true;
    
    if (!this.state.Component) {
      getComponent().then(Component => {
        if (!this._isMount) return;
        AsyncComponent.Component = Component
        this.setState({ Component })
      })
    }
  }

  componentWillUnmount() {
    this._isMount = false;
  }

  render() {
    const { Component } = this.state
    if (Component) {
      return <Component {...this.props} />
    }
    return <PageLoading />
  }
}
