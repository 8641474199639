export const INIT_STATE = "INIT_STATE";
export const CHANGE_STATE = "CHANGE_STATE";
export const REQUEST_FETCH_PRODUCT = "REQUEST_FETCH_PRODUCT";
export const SUCCESS_FETCH_PRODUCT = "SUCCESS_FETCH_PRODUCT";
export const FAIL_FETCH_PRODUCT = "FAIL_FETCH_PRODUCT";
export const REQUEST_PRODUCT_DETAIL = "REQUEST_PRODUCT_DETAIL";
export const SUCCESS_PRODUCT_DETAIL = "SUCCESS_PRODUCT_DETAIL";
export const FAIL_PRODUCT_DETAIL = "FAIL_PRODUCT_DETAIL";

export const init = type => ({
  type: INIT_STATE,
  productType: type
});

export const changeState = data => ({
  type: CHANGE_STATE,
  data
});

export const fetchProduct = ({ type, history, product }) => ({
  type: REQUEST_FETCH_PRODUCT,
  productType: type,
  history,
  product
});

export const getProduct = ({ productType, seq }) => ({
  type: REQUEST_PRODUCT_DETAIL,
  productType,
  seq
});
