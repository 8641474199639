import { call, put, takeLatest } from "redux-saga/effects";

import {
  REQUEST_FETCH_SNAPLIST,
  SUCCESS_FETCH_SNAPLIST,
  FAIL_FETCH_SNAPLIST
} from "@/Actions/snapList";

import { snap } from "./api";
import Toast from "@/Components/Toast";

function* fetchSnapList(action) {
  try {
    const res = yield call(snap.getSnap);

    const jeju = res.jeju ? res.jeju : false;
    const main = res.main ? res.main : false;
    const simple = res.simple ? res.simple : false;
    const dme = res.dme ? res.dme : false;
    const dvd = res.dvd ? res.dvd : false;
    const remind = res.remind ? res.remind : false;
    const family = res.family ? res.family : false;

    let jejuTemp = {};
    let mainTemp = {};
    let simpleTemp = {};
    let dmeTemp = {};
    let dvdTemp = {};
    let remindTemp = {};
    let familyTemp = {};

    if (jeju) {
      jejuTemp = {
        enterpriseId: jeju.enterpriseId,
        mainImg: jeju.mainImg,
        membershipType: jeju.membershipType,
        membershipTypeStr: jeju.membershipTypeStr,
        isShow: jeju.isShow,
        isEdit: jeju.isEdit,
        type: "jeju",
        productSeq: jeju.productSeq,
        status: jeju.status,
        returnReason: jeju.returnReason,
        operatorName: jeju.operatorName,
        operatorPhone: jeju.operatorPhone,
        periodFrom: jeju.periodFrom,
        periodTo: jeju.periodTo
      };
    }

    if (main) {
      mainTemp = {
        enterpriseId: main.enterpriseId,
        mainImg: main.mainImg,
        membershipType: main.membershipType,
        membershipTypeStr: main.membershipTypeStr,
        isShow: main.isShow,
        isEdit: main.isEdit,
        type: "main",
        productSeq: main.productSeq,
        status: main.status,
        returnReason: main.returnReason,
        operatorName: main.operatorName,
        operatorPhone: main.operatorPhone,
        periodFrom: main.periodFrom,
        periodTo: main.periodTo
      };
    }

    if (simple) {
      simpleTemp = {
        enterpriseId: simple.enterpriseId,
        mainImg: simple.mainImg,
        membershipType: simple.membershipType,
        membershipTypeStr: simple.membershipTypeStr,
        isShow: simple.isShow,
        isEdit: simple.isEdit,
        type: "simple",
        productSeq: simple.productSeq,
        status: simple.status,
        returnReason: simple.returnReason,
        operatorName: simple.operatorName,
        operatorPhone: simple.operatorPhone,
        periodFrom: simple.periodFrom,
        periodTo: simple.periodTo
      };
    }

    if (dme) {
      dmeTemp = {
        enterpriseId: dme.enterpriseId,
        mainImg: dme.mainImg,
        membershipType: dme.membershipType,
        membershipTypeStr: dme.membershipTypeStr,
        isShow: dme.isShow,
        isEdit: dme.isEdit,
        type: "dme",
        productSeq: dme.productSeq,
        status: dme.status,
        returnReason: dme.returnReason,
        operatorName: dme.operatorName,
        operatorPhone: dme.operatorPhone,
        periodFrom: dme.periodFrom,
        periodTo: dme.periodTo
      };
    }

    if (dvd) {
      dvdTemp = {
        enterpriseId: dvd.enterpriseId,
        mainImg: dvd.mainImg,
        membershipType: dvd.membershipType,
        membershipTypeStr: dvd.membershipTypeStr,
        isShow: dvd.isShow,
        isEdit: dvd.isEdit,
        type: "dvd",
        productSeq: dvd.productSeq,
        status: dvd.status,
        returnReason: dvd.returnReason,
        operatorName: dvd.operatorName,
        operatorPhone: dvd.operatorPhone,
        periodFrom: dvd.periodFrom,
        periodTo: dvd.periodTo
      };
    }

    if (remind) {
      remindTemp = {
        enterpriseId: remind.enterpriseId,
        mainImg: remind.mainImg,
        membershipType: remind.membershipType,
        membershipTypeStr: remind.membershipTypeStr,
        isShow: remind.isShow,
        isEdit: remind.isEdit,
        type: "remind",
        productSeq: remind.productSeq,
        status: remind.status,
        returnReason: remind.returnReason,
        operatorName: remind.operatorName,
        operatorPhone: remind.operatorPhone,
        periodFrom: remind.periodFrom,
        periodTo: remind.periodTo
      };
    }

    if (family) {
      familyTemp = {
        enterpriseId: family.enterpriseId,
        mainImg: family.mainImg,
        membershipType: family.membershipType,
        membershipTypeStr: family.membershipTypeStr,
        isShow: family.isShow,
        isEdit: family.isEdit,
        type: "family",
        productSeq: family.productSeq,
        status: family.status,
        returnReason: family.returnReason,
        operatorName: family.operatorName,
        operatorPhone: family.operatorPhone,
        periodFrom: family.periodFrom,
        periodTo: family.periodTo
      };
    }

    yield put({
      type: SUCCESS_FETCH_SNAPLIST,
      jeju: jejuTemp,
      main: mainTemp,
      simple: simpleTemp,
      dme: dmeTemp,
      dvd: dvdTemp,
      remind: remindTemp,
      family: familyTemp
    });
  } catch (err) {
    console.log(err);
    let msg = "";
    try {
      msg = err.msg;
    } catch (err) {
      msg = "서버에러가 발생하였습니다.";
    }
    Toast.error(msg);

    action.history.push("/login");

    yield put({
      type: FAIL_FETCH_SNAPLIST
    });
  }
}

export default function* watchfetchRoom() {
  yield takeLatest(REQUEST_FETCH_SNAPLIST, fetchSnapList);
}
