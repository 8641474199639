import React, { Component } from 'react';
import { Timer } from '../utils';

const C = WrappedComponent => class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHover: false,
    }
  }
  
  componentDidMount() {
    const { data } = this.props;
    
    this.timeout = new Timer(() => {
      const container = document.getElementById('react-toast');
      container.removeChild(document.getElementById(data.id));
    }, data.timeout);
  }

  mouseEnter = () => {
    if (this.state.isHover) return;
    this.setState({
      isHover: true,
    }, () => {
      this.timeout.pause();
    });
  }

  mouseLeave = () => {
    if (!this.state.isHover) return;
    this.setState({
      isHover: false,
    }, () => {
      this.timeout.start();
    });
  }

  removeSelf = () => {
    this.timeout.stop();
    const { data } = this.props;
    const container = document.getElementById('react-toast')
    container.removeChild(document.getElementById(data.id));
  }

  render() {
    return (
      <WrappedComponent
        {...this.props}
        {...this.state}
        removeSelf={this.removeSelf}
        mouseEnter={this.mouseEnter}
        mouseLeave={this.mouseLeave} />
    )
  }
}

export default C;