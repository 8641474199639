import {
  REQUEST_FETCH_USER_INFO,
  SUCCESS_FETCH_USER_INFO,
  FAIL_FETCH_USER_INFO,

  REQUEST_FETCH_LOGIN,
  FAIL_FETCH_LOGIN,

  SUCCESS_FETCH_LOGOUT,
  CHANGE_USER_INFO,
} from '@/Actions/user'

const initialState = {
  id: '',                           // 아이디
  name: '',                         // 사용자명
  phone: '',                        // 사용자 폰번호
  isCertified: false,               // 폰번호 인증여부
  enterpriseId: '',                 // 업체ID
  enterpriseName: '',               // 업체명
  presidentName: '',                // 대표자명
  jibunAddress: '',                 // 지번주소
  roadAddress: '',                  // 도로명주소
  showAddress: false,               // 주소 공개여부 - false일 경우 비공개하기에 체크
  logoImg: '',                      // 로고 이미지
  logoImgBase64: '',                // 로고 이미지 (업체명으로 만듬)
  isFreelancer: false,              // 프리랜서 여부 - true일 경우 사업자 등록증 없음 체크
  businessId: '',                   // 사업자등록번호
  businessIdImg: '',                // 사업자등록증 이미지
  homepage: '',                     // 홈페이지
  isLoadingUserInfo: false,         // 로딩
  isLoggedIn: false,                // 로그인여부
  errorMsg: '',                     // 로그인 실패 메시지
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FETCH_LOGIN:
    case REQUEST_FETCH_USER_INFO:
    case CHANGE_USER_INFO:
      return {
        ...state,
        isLoadingUserInfo: true,
      }

    case SUCCESS_FETCH_USER_INFO:
      return {
        ...state,
        ...action,
        isLoggedIn: true,
        isLoadingUserInfo: false,
      };

    case FAIL_FETCH_LOGIN:
      return {
        ...state,
        errorMsg: action.errorMsg,
        isLoggedIn: false,
        isLoadingUserInfo: false,
      }

    case FAIL_FETCH_USER_INFO:
      return {
        ...state,
        isLoggedIn: false,
        isLoadingUserInfo: false,
      }

    case SUCCESS_FETCH_LOGOUT:
      return initialState

    default:
      return state
  }
}

export default user
