import React, { lazy, Suspense } from "react";

// APP
import { connect } from "react-redux";
import objectFitImages from "object-fit-images";
import { includes } from "lodash";

import { fetchUserInfo } from "@/Actions/user";
import Toast from "@/Components/Toast";
// End APP

import { isMobile } from "../config";

import Spinner from "@/App/utils/components/Spinner";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import theme from "../themes/default";

// import Desktop from "./platform/desktop";
// import Mobile from "./platform/mobile";
const Desktop = lazy(() => import("./platform/desktop"));
const Mobile = lazy(() => import("./platform/mobile"));

export const ResetStyle = createGlobalStyle`
  * {
    font-family: 'Spoqa Han Sans', sans-serif !important;
    box-sizing: border-box;
  }
`;

const Loading = styled(Spinner)`
  height: 100vh;
`;

class App extends React.Component {
  componentDidMount = () => {
    if (
      window.location.pathname !== "/404" &&
      window.location.pathname !== "/500" &&
      window.location.pathname !== "/login" &&
      !includes(window.location.pathname, "/signup/term/") &&
      !includes(window.location.pathname, "/signup/form/") &&
      !includes(window.location.pathname, "/term/") &&
      window.location.pathname !== "/force/term"
    ) {
      const { fetchUserInfo } = this.props;
      fetchUserInfo();
    }

    objectFitImages();
    Toast.createContainer({});
  };
  render() {
    const { isLoggedIn } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <ResetStyle />
        <Suspense fallback={<Loading />}>
          {isMobile ? <Mobile /> : <Desktop isLoggedIn={isLoggedIn} />}
        </Suspense>
      </ThemeProvider>
    );
  }
}

export default connect(({ user: { isLoggedIn } }) => ({ isLoggedIn }), {
  fetchUserInfo
})(App);
