import { call, put, takeLatest } from "redux-saga/effects";
import { apiCall } from "@/Utils";

import {
  REQUEST_SIGNUP_TERMS,
  SUCCESS_SIGNUP_TERMS,
  FAIL_SIGNUP_TERMS,
  FETCH_SIGNUP_TERMS,
  REQUEST_SIGNUP_USER_INFO,
  SUCCESS_SIGNUP_USER_INFO,
  FAIL_SIGNUP_USER_INFO,
  FETCH_SIGNUP_USER_INFO,
  REQUEST_SIGNUP,
  successSignUp,
  failSignUp
} from "@/Actions/auth/signUp.actions";

const getSignUpTermsApi = userType =>
  apiCall.get("/api/term/join", {
    userType // (유저: general / 작가: enterprise)
  });

const getSignUpUserInfo = code => apiCall.get("/api/user/register", { code });

const signUpUser = params => apiCall.post("/api/user/register", params);

function* fetchSignUpUser(action) {
  try {
    const { payload } = action;
    const temp = { ...payload.userInfo };
    const res = yield call(signUpUser, {
      ...payload.userInfo, ...payload.isLoading
    });

    yield put(successSignUp());

    window.location.href = "/";
    console.log(temp);
  } catch (err) {
    console.log(err);
    yield put(failSignUp());
  }
}

function* fetchSignUpTerms(action) {
  try {
    const { payload } = action;
    const res = yield call(getSignUpTermsApi, payload.userType);
    // const res = [
    //   {
    //     seq: 0, // (약관번호),
    //     termsHistorySeq: 0, // (약관히스토리번호)
    //     name: '이용동의 이용동의 약관', // (약관명)
    //     shortName: '이용동의', // (약관 짧은이름)
    //     required: true, // (필수여부)
    //     termText: '내용~', // (약관 내용)
    //     termsDate: '', // (약관개정일자)
    //     agree: '', // (동의여부)
    //   }
    // ]
    yield put({
      type: SUCCESS_SIGNUP_TERMS
    });
    yield put({
      type: FETCH_SIGNUP_TERMS,
      payload: {
        list: res
      }
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: FAIL_SIGNUP_TERMS
    });
  }
}

function* fetchSignUpUserInfo(action) {
  try {
    const { payload } = action;
    const data = yield call(getSignUpUserInfo, payload.code);

    yield put({
      type: SUCCESS_SIGNUP_USER_INFO
    });
    yield put({
      type: FETCH_SIGNUP_USER_INFO,
      payload: {
        data
      }
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: FAIL_SIGNUP_USER_INFO
    });
  }
}

export default function* watchFetchTerms() {
  yield takeLatest(REQUEST_SIGNUP, fetchSignUpUser);
  yield takeLatest(REQUEST_SIGNUP_TERMS, fetchSignUpTerms);
  yield takeLatest(REQUEST_SIGNUP_USER_INFO, fetchSignUpUserInfo);
}
