import {
  REQUEST_SATELLITE_IMAGES,
  SUCCESS_SATELLITE_IMAGES,
  FAIL_SATELLITE_IMAGES,
  CLEAR_SATELLITE_IMAGES
} from "@/Actions/weather/satellite";

const initialState = {
  isLoading: true,
  images: null
};

const satelliteReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_SATELLITE_IMAGES:
      return {
        ...state,
        isLoading: true
      };
    case SUCCESS_SATELLITE_IMAGES:
      return {
        ...state,
        images: action.payload.images,
        isLoading: false
      };
    case FAIL_SATELLITE_IMAGES:
      return {
        ...state,
        isLoading: false
      };
    case CLEAR_SATELLITE_IMAGES:
      return initialState;
    default:
      return state;
  }
};
export default satelliteReducer;
