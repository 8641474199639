import React from "react";
import styled, { css } from "styled-components";
import { prop } from "styled-tools";

const loaderStyle = css`
  height: ${prop("size", "50px")};
  width: ${prop("size", "50px")};
  color: rgba(209, 173, 143, 0.2);
  position: relative;
  display: inline-block;
  border: 5px solid;
  border-radius: 50%;
  border-right-color: rgba(209, 173, 143, 1);
  animation: rotate 1s linear infinite;

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const StyleCirclePath = styled.div`
  ${loaderStyle};
`;

export type CirclePathProps = {
  size?: string;
};

const CirclePath: React.FC<
  React.HTMLAttributes<HTMLDivElement> & CirclePathProps
> = ({ ...props }) => <StyleCirclePath {...props} />;

export default CirclePath;
