import React from 'react';

import { Loading } from './styled';

const Circle = ({width, height, color}) => {
  return(
    <Loading width={width} height={height} color={color}>
      <div className="sk-child"></div>
      <div className="sk-child sk-circle2"></div>
      <div className="sk-child sk-circle3"></div>
      <div className="sk-child sk-circle4"></div>
      <div className="sk-child sk-circle5"></div>
      <div className="sk-child sk-circle6"></div>
      <div className="sk-child sk-circle7"></div>
      <div className="sk-child sk-circle8"></div>
      <div className="sk-child sk-circle9"></div>
      <div className="sk-child sk-circle10"></div>
      <div className="sk-child sk-circle11"></div>
      <div className="sk-child sk-circle12"></div>
    </Loading>
  )
}

export default Circle;