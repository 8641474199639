import apiCall from '@/Utils/apiCall';

const login = ({ email, password }) =>
  apiCall.post('/api/user/login', {
    email: email,
    password: password
  });

const logout = () => apiCall.post('/api/user/logout');

const getUser = () => apiCall.get('/api/user/profile');

export default {
  login,
  logout,
  getUser
};
