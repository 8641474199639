import {
  REQUEST_FORECAST,
  SUCCESS_FORECAST,
  FAIL_FORECAST,
  CLEAR_FORECAST
} from "@/Actions/weather/forecast";

const initialState = {
  isLoading: true,
  today: {
    data: null,
    updatedAt: null
  },
  hourly: {
    data: null,
    updatedAt: null
  },
  weekly: {
    data: null,
    updatedAt: null
  }
};

const forecastReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_FORECAST:
      return {
        ...state,
        isLoading: true
      };
    case SUCCESS_FORECAST:
      return {
        ...state,
        today: payload.today,
        hourly: payload.hourly,
        weekly: payload.weekly,
        isLoading: false
      };
    case FAIL_FORECAST:
      return {
        ...state,
        isLoading: false
      };
    case CLEAR_FORECAST:
      return initialState;
    default:
      return state;
  }
};

export default forecastReducer;
