export const REQUEST_SIGNUP_TERMS = "REQUEST_SIGNUP_TERMS";
export const SUCCESS_SIGNUP_TERMS = "SUCCESS_SIGNUP_TERMS";
export const FAIL_SIGNUP_TERMS = "FAIL_SIGNUP_TERMS";
export const FETCH_SIGNUP_TERMS = "FETCH_SIGNUP_TERMS";

export const REQUEST_SIGNUP_USER_INFO = "REQUEST_SIGNUP_USER_INFO";
export const SUCCESS_SIGNUP_USER_INFO = "SUCCESS_SIGNUP_USER_INFO";
export const FAIL_SIGNUP_USER_INFO = "FAIL_SIGNUP_USER_INFO";
export const FETCH_SIGNUP_USER_INFO = "FETCH_SIGNUP_USER_INFO";
export const CHANGE_SIGNUP_USER_INFO = "CHANGE_SIGNUP_USER_INFO";

export const REQUEST_SIGNUP = "REQUEST_SIGNUP";
export const SUCCESS_SIGNUP = "SUCCESS_SIGNUP";
export const FAIL_SIGNUP = "FAIL_SIGNUP";

export const requestSignUpTerms = userType => ({
  type: REQUEST_SIGNUP_TERMS,
  payload: {
    userType
  }
});

export const requestSignUpUserInfo = code => ({
  type: REQUEST_SIGNUP_USER_INFO,
  payload: {
    code
  }
});

export const changeSignUpUserInfo = ({ changeData }) => {
  return {
    type: CHANGE_SIGNUP_USER_INFO,
    payload: {
      changeData
    }
  };
};

export const requestSignUp = ({ userInfo }) => {
  return {
    type: REQUEST_SIGNUP,
    payload: {
      userInfo
    }
  };
};

export const successSignUp = () => ({
  type: SUCCESS_SIGNUP
});

export const failSignUp = () => ({
  type: FAIL_SIGNUP
});
