import React from 'react';

const Icon = ({ name, width, height, color }) => {
  switch (name) {
    case 'info': // alert info
      return <svg width="55" height="55" viewBox="0 0 55 55"><g transform="translate(-252 -2216)"><circle fill="#333" cx="27.5" cy="27.5" r="27.5" transform="translate(252 2216)"/><g transform="translate(275 2233)"><path fill="#fff" d="M2.036 8.144H.113v-.792l5.769-1.583h.566V18.89l1.7.679v.566H0v-.566l2.036-.679zM1.47 2.036C1.47.566 2.715 0 3.959 0c1.357 0 2.488.566 2.488 2.036 0 1.7-1.244 2.149-2.488 2.149S1.47 3.733 1.47 2.036z" /></g></g></svg>
    
    case 'success':
      return <svg width="55" height="55" viewBox="0 0 55 55"><g transform="translate(-252 -2337)"><circle fill="#57d378" cx="27.5" cy="27.5" r="27.5" transform="translate(252 2337)" /><path fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="4px" d="M1.4 8.1l7 6.8L19.9 1.3" transform="translate(268.6 2355.7)" /></g></svg>
    
    case 'error': // alert error
      return <svg width="55" height="55" viewBox="0 0 55 55"><g transform="translate(-252 -2458)"><circle fill="#f03c3c" cx="27.5" cy="27.5" r="27.5" transform="translate(252 2458)"/><g transform="translate(277 2475)"><path fill="#fff" d="M0 19.318A2.479 2.479 0 0 1 2.5 16.7 2.417 2.417 0 0 1 5 19.318a2.327 2.327 0 0 1-2.5 2.5 2.385 2.385 0 0 1-2.5-2.5zM4.659 0l-.568 14.659H.8L.227 0z" /></g></g></svg>

    case 'x':
      return <svg width="14" height="14" viewBox="0 0 15.414 15.414"><g transform="translate(-.693 -.693)"><path fill="none" stroke="#bdbdbd" strokeMiterlimit="10" strokeWidth="2px" d="M0 0l14 14" transform="translate(1.4 1.4)"/><path fill="none" stroke="#bdbdbd" strokeMiterlimit="10" strokeWidth="2px" d="M14 0L0 14" transform="translate(1.4 1.4)"/></g></svg>

    default:
      break;
  }
}

export default Icon;