import { takeLatest } from 'redux-saga/effects'

import {
  SET_INIT
} from '@/Actions/common'

function* init(action) {
  yield console.log('init', action.init)
}

export default function* watchfetchRoom() {
  yield takeLatest(SET_INIT, init)
}
