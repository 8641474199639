import React from "react";
import styled, { keyframes } from "styled-components";
import { prop } from "styled-tools";
const circleBounceDelay = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1);
  }
`;

const StyleCircleDot = styled.div`
  position: relative;
  width: ${prop("size", "40px")};
  height: ${prop("size", "40px")};

  .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .sk-child:before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: rgba(209, 173, 143, 1);
    border-radius: 100%;
    animation: ${circleBounceDelay} 1.2s infinite ease-in-out both;
  }
  .sk-circle2 {
    transform: rotate(30deg);
  }
  .sk-circle3 {
    transform: rotate(60deg);
  }
  .sk-circle4 {
    transform: rotate(90deg);
  }
  .sk-circle5 {
    transform: rotate(120deg);
  }
  .sk-circle6 {
    transform: rotate(150deg);
  }
  .sk-circle7 {
    transform: rotate(180deg);
  }
  .sk-circle8 {
    transform: rotate(210deg);
  }
  .sk-circle9 {
    transform: rotate(240deg);
  }
  .sk-circle10 {
    transform: rotate(270deg);
  }
  .sk-circle11 {
    transform: rotate(300deg);
  }
  .sk-circle12 {
    transform: rotate(330deg);
  }
  .sk-circle2:before {
    animation-delay: -1.1s;
  }
  .sk-circle3:before {
    animation-delay: -1s;
  }
  .sk-circle4:before {
    animation-delay: -0.9s;
  }
  .sk-circle5:before {
    animation-delay: -0.8s;
  }
  .sk-circle6:before {
    animation-delay: -0.7s;
  }
  .sk-circle7:before {
    animation-delay: -0.6s;
  }
  .sk-circle8:before {
    animation-delay: -0.5s;
  }
  .sk-circle9:before {
    animation-delay: -0.4s;
  }
  .sk-circle10:before {
    animation-delay: -0.3s;
  }
  .sk-circle11:before {
    animation-delay: -0.2s;
  }
  .sk-circle12:before {
    animation-delay: -0.1s;
  }
`;

export type CircleDotProps = {
  size?: string;
};
const CircleDot: React.FC<
  React.HTMLAttributes<HTMLDivElement> & CircleDotProps
> = ({ ...props }) => (
  <StyleCircleDot {...props}>
    <div className="sk-child" />
    <div className="sk-child sk-circle2" />
    <div className="sk-child sk-circle3" />
    <div className="sk-child sk-circle4" />
    <div className="sk-child sk-circle5" />
    <div className="sk-child sk-circle6" />
    <div className="sk-child sk-circle7" />
    <div className="sk-child sk-circle8" />
    <div className="sk-child sk-circle9" />
    <div className="sk-child sk-circle10" />
    <div className="sk-child sk-circle11" />
    <div className="sk-child sk-circle12" />
  </StyleCircleDot>
);

export default CircleDot;
