import { call, put, takeLatest } from "redux-saga/effects";
import { apiCall } from "@/Utils";
import moment from "moment";

import {
  REQUEST_SATELLITE_IMAGES,
  SUCCESS_SATELLITE_IMAGES,
  FAIL_SATELLITE_IMAGES
} from "@/Actions/weather/satellite";

/**
const data = await apiCall.get("/api/weather/radar", {
  data: "CMP_WRC",
  dataType: "JSON",
  numOfRows: 1,
  time: moment().format("YYYYMMDD"),
  pageNo: 1
});

const images = data.response.body.items.item[0]["rdr-img-file"]
  .replace(/[\[\]]+/g, "")
  .trim()
  .split(", ");
 */

const getSatellite = params => apiCall.get("/api/weather/satellite", params);
function* requestSatelliteImages() {
  try {
    const data = yield call(getSatellite, {
      area: "ko",
      data: "ir105", //영상구분 -적외영상(ir105) -가시영상(vi006) -수증기영상(wv069) -단파적외영상(sw038) -RGB 컬러(rgbt) -RGB 주야간합성(rgbdn)
      dataType: "JSON",
      numOfRows: 10,
      pageNo: 1,
      sat: "G2",
      time: moment().format("YYYYMMDD")
    });

    const images = data.response.body.items.item[0]["satImgC-file"]
      .replace(/[\[\]]+/g, "")
      .trim()
      .split(", ");

    yield put({
      type: SUCCESS_SATELLITE_IMAGES,
      payload: {
        images
      }
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: FAIL_SATELLITE_IMAGES
    });
  }
}

export default function* watchFetchSatellite() {
  yield takeLatest(REQUEST_SATELLITE_IMAGES, requestSatelliteImages);
}
