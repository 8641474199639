import {
  INIT_STATE,
  CHANGE_STATE,
  REQUEST_FETCH_PRODUCT,
  SUCCESS_FETCH_PRODUCT,
  FAIL_FETCH_PRODUCT,
  REQUEST_PRODUCT_DETAIL,
  SUCCESS_PRODUCT_DETAIL,
  FAIL_PRODUCT_DETAIL
} from "@/Actions/product";

import * as _ from "lodash";

import main from "./data/main";
import jeju from "./data/jeju";
import simple from "./data/simple";
import dme from "./data/dme";
import dvd from "./data/dvd";
import remind from "./data/remind";
import family from "./data/family";

const initialState = {
  isLoading: false
};

const detailsGroup = details =>
  _.reduce(
    details,
    (result, detail) => {
      const arrayIndex = Math.floor(detail.index / 10);

      if (!result[arrayIndex]) {
        result[arrayIndex] = [];
      }
      result[arrayIndex].push(detail);
      return result;
    },
    []
  );

const common = (state = initialState, action) => {
  switch (action.type) {
    case INIT_STATE:
      return {
        ...initialState,
        ...(action.productType === "jeju"
          ? jeju.initialState
          : action.productType === "main"
          ? main.initialState
          : action.productType === "simple"
          ? simple.initialState
          : action.productType === "dme"
          ? dme.initialState
          : action.productType === "remind"
          ? remind.initialState
          : action.productType === "family"
          ? family.initialState
          : dvd.initialState)
      };

    case CHANGE_STATE:
      return {
        ...state,
        ...action.data
      };

    case REQUEST_PRODUCT_DETAIL:
    case REQUEST_FETCH_PRODUCT:
      return {
        ...state,
        isLoading: true
      };

    case SUCCESS_FETCH_PRODUCT:
      return initialState;

    case SUCCESS_PRODUCT_DETAIL:
      return {
        ...state,
        ...action.data,
        details: detailsGroup(action.data.details),
        isLoading: false
      };

    case FAIL_PRODUCT_DETAIL:
    case FAIL_FETCH_PRODUCT:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
};

export default common;
