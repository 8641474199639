import {
  REQUEST_DVD_PORTFOLIO,
  FAIL_DVD_PORTFOLIO,
  SUCCESS_DVD_PORTFOLIO,
  ADD_DVD_PORTFOLIO_FOLDER,
  CHANGE_DVD_PORTFOLIO_STATE,
  SET_ERRORS,
  CLEAR_ERRORS
} from "../Actions/portfolioDvd";

const initialPortfolioDvd = {
  mainFolder: null,
  folders: [],
  isLoading: false,
  errors: null
};

const portfolioDvdReducer = (state = initialPortfolioDvd, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_DVD_PORTFOLIO:
      return {
        ...state,
        isLoading: true
      };
    case SUCCESS_DVD_PORTFOLIO:
      return {
        ...state,
        mainFolder: payload.data.find(folder => folder.isRepresent),
        folders: [...payload.data.filter(folder => !folder.isRepresent)],
        isLoading: false
      };
    case FAIL_DVD_PORTFOLIO:
      return {
        ...state,
        isLoading: false
      };
    case ADD_DVD_PORTFOLIO_FOLDER:
      // 대표 폴더
      if (payload.folder.isRepresent) {
        return {
          ...state,
          mainFolder: payload.folder
        };
      }

      // 일반 폴더
      return {
        ...state,
        folders: [payload.folder, ...state.folders]
      };
    case CHANGE_DVD_PORTFOLIO_STATE:
      return {
        ...state,
        ...payload
      };
    case SET_ERRORS:
      return {
        ...state,
        errors: {
          ...payload.errors
        }
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default portfolioDvdReducer;
