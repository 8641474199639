const initialState = {
  seq: '',                // 상품번호 (수정시)

  mainImg: '',            // 메인 사진
  contact: '',            // 연락처
  schedule: '',           // 스케쥴
  intro: '',              // 소개글

  // 촬영 상품 구성 특징
  shootDrone: false,      // 드론촬영
  shootNight: false,      // 야간촬영
  shootIndoor: false,     // 실내세트장보유
  shootCustom: false,     // 커스텀촬영
  shootDuo: false,        // 2인 촬영
  shootHanbok: false,     // 한복촬영

  // 촬영 스타일 특징
  pictorial: false,       // 화보형
  classic: false,         // 클래식
  trendy: false,          // 트렌디
  special: false,         // 특별한
  vivid: false,           // 선명한
  dramatic: false,        // 드라마틱
  lovely: false,          // 러블리
  romantic: false,        // 로맨틱
  warm: false,            // 따뜻한
  vintage: false,         // 빈티지
  comfy: false,           // 편안한
  clean: false,           // 깨끗한

  // 추가 옵션
  option: '',

  // 제휴 업체 정보
  collaboDressMakeupInfo: '',     // 드레스 메이크업
  collaboSuitHanbokInfo: '',      // 수트 한복

  event: '',              // 자체 이벤트

  reviewImg: [],          // 후기 인증샷 이미지
  albumImg: [],           // 앨범 이미지
  etcImg: [],             // 기타 이미지

  // 서비스 규정
  offerOriginalFileRule: '',          // 원본파일 제공
  offerCorrectionFileRuleType: '',    // 보정파일 제공 - 0: 촬영 후, 1: 고객 셀렉 후
  offerCorrectionFileRule: '',        // 보정파일 제공
  offerPrintPictureRuleType: '',      // 앨범/액자/인화사진 제공 - 0: 촬영후, 1: 고객 셀렉 후
  offerPrintPictureRule: '',          // 앨범/액자/인화사진 제공

  // 기타 환불 규정
  periodStoreFileRule: '',            // 파일보관기간
  offerTypeFileRule: '',              // 파일 제공 방식
  refundUserRule: '',                 // 고객측 취소, 환불규정
  refundEnterpriseRule: '',           // 업체측 취소, 환불규정
  policyBadWeatherRule: '',           // 기상악화 시 운영방침

  details: [],             // 상품 구성
}

export default {
  initialState
}