import {
  REQUEST_SIGNUP_TERMS,
  FETCH_SIGNUP_TERMS,
  FAIL_SIGNUP_TERMS,
  REQUEST_SIGNUP_USER_INFO,
  FETCH_SIGNUP_USER_INFO,
  FAIL_SIGNUP_USER_INFO,
  CHANGE_SIGNUP_USER_INFO,
  REQUEST_SIGNUP
} from "@/Actions/auth/signUp.actions";

const initialState = {
  terms: {
    list: [],
    isLoading: true
  },
  userInfo: {
    data: {
      id: "",
      password: "", // (패스워드)
      passwordConfirm: "", // (패스워드 재입력)
      name: "", // (사용자명)
      phone: "", // (연락처)
      confirmPhone: "", // 인증 완료했을 경우에만 저장됨, 등록할때 연락처 변경 여부 판별용
      isCertified: false,

      enterpriseId: "",
      enterpriseName: "",
      presidentName: "",
      contact: "",
      jibunAddress: "",
      roadAddress: "",
      detailAddress: "",
      location: "",
      showAddress: "",
      businessId: "",
      isFreelancer: "",
      businessIdImg: "",
      logoImg: "",
      homepage: ""
    },
    isLoading: true
  }
};

/*
{
  seq: '', // (약관번호),
  termsHistorySeq: '', // (약관히스토리번호)
  name: '', // (약관명)
  shortName: '', // (약관 짧은이름)
  required: true, // (필수여부)
  termText: '', // (약관 내용)
  termsDate: '', // (약관개정일자)
  agree: '', // (동의여부)
}
*/

const signUpReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_SIGNUP_TERMS:
      return {
        ...state,
        terms: {
          ...state.terms,
          isLoading: true
        }
      };

    case FETCH_SIGNUP_TERMS:
      return {
        ...state,
        terms: {
          list: payload.list,
          isLoading: false
        }
      };

    case FAIL_SIGNUP_TERMS:
      return {
        ...state,
        terms: {
          isLoading: false
        }
      };

    // 회원가입 유저 정보 가져오기
    case REQUEST_SIGNUP_USER_INFO:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          isLoading: true
        }
      };
    case FETCH_SIGNUP_USER_INFO:
      return {
        ...state,
        userInfo: {
          data: { ...state.userInfo.data, ...payload.data },
          isLoading: false
        }
      };

    case FAIL_SIGNUP_USER_INFO:
      return {
        ...state,
        userInfo: {
          isLoading: false
        }
      };
    case CHANGE_SIGNUP_USER_INFO:
      return {
        ...state,
        userInfo: {
          data: {
            ...state.userInfo.data,
            ...payload.changeData
          },
          isLoading: false
        }
      };

    case REQUEST_SIGNUP:
      return {
        ...state,
        userInfo: {
          data: {
            ...state.userInfo
          },
          isLoading: true
        }
      };

    default:
      return state;
  }

};

export default signUpReducer;
