export const REQUEST_TERMS = "REQUEST_TERMS";
export const SUCCESS_TERMS = "SUCCESS_TERMS";
export const FAIL_TERMS = "FAIL_TERMS";
export const FETCH_TERMS = "FETCH_TERMS";
export const CHANGE_TERMS_SEQ = "CHANGE_TERMS_SEQ";

export const requestTerms = ({ termSeq }) => {
  return {
    type: REQUEST_TERMS,
    payload: {
      termSeq
    }
  };
};

export const successTerms = () => ({
  type: SUCCESS_TERMS
});
export const failTerms = () => ({
  type: FAIL_TERMS
});
export const fetchTerms = ({ data }) => {
  return {
    type: FETCH_TERMS,
    payload: {
      data
    }
  };
};

export const changeTermsHistory = ({ seq }) => {
  return {
    type: CHANGE_TERMS_SEQ,
    payload: {
      seq
    }
  };
};
