import { all, fork } from "redux-saga/effects";
import watchFetchCommon from "./watchFetchCommon";
import watchFetchPortfolio from "./watchFetchPortfolio";
import watchFetchPortfolioDvd from "./watchFetchPortfolioDvd";
import watchFetchProduct from "./watchFetchProduct";
import watchFetchSnapList from "./watchFetchSnapList";
import watchFetchUser from "./watchFetchUser";
import watchFetchDashboard from "./watchFetchDashboard";

// weather
import watchFetchRadar from "./weather/watchFetchRadar";
import watchFetchSatellite from "./weather/watchFetchSatellite";
import watchFetchForecast from "./weather/watchFetchForecast";

// auth
import auth from "./auth";

// terms
import terms from "./terms";

export default function* rootSaga() {
  yield all([
    fork(watchFetchCommon),
    fork(watchFetchProduct),
    fork(watchFetchPortfolio),
    fork(watchFetchPortfolioDvd),
    fork(watchFetchSnapList),
    fork(watchFetchUser),
    fork(watchFetchDashboard),

    // weather
    fork(watchFetchRadar),
    fork(watchFetchSatellite),
    fork(watchFetchForecast),

    ...auth,
    ...terms
  ]);
}
