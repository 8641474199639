import styled from 'styled-components';
import { keyframes } from 'styled-components';

const stretchdelay = keyframes`
  0%, 40%, 100% { 
    transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
  }
`

export const Loading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${({width}) => width ? width : 50}px;
  height: ${({height}) => height ? height : 40}px;
  text-align: center;
  font-size: 10px;

  & > div {
    display: inline-block;
    height: 100%;
    width: 6px;
    margin: 0 1px;
    background-color: ${({color}) => color ? color : 'rgba(209, 173, 143, 1)'};

    animation: ${stretchdelay} 1.2s infinite ease-in-out;
  }

  & > div:nth-child(2) {
    animation-delay: -1.1s;
  }
  & > div:nth-child(3) {
    animation-delay: -1.0s;
  }
  & > div:nth-child(4) {
    animation-delay: -0.9s;
  }
  & > div:nth-child(5) {
    animation-delay: -0.8s;
  }
`;