import { combineReducers } from "redux";

import common from "./common";
import portfolio from "./portfolio";
import portfolioDvd from "./portfolioDvd";
import product from "./product";
import snap from "./snapList";
import user from "./user";
import dashboard from "./dashboard";

// weather
import radar from "./weather/radar";
import satellite from "./weather/satellite";
import forecast from "./weather/forecast";

import auth from "./auth";
import terms from "./terms";

const rootReducer = combineReducers({
  common,
  portfolio,
  portfolioDvd,
  product,
  snap,
  user,
  dashboard,
  radar,
  satellite,
  forecast,

  auth,
  terms
});

export default rootReducer;
