import { call, put, takeLatest } from "redux-saga/effects";

import {
  REQUEST_DVD_PORTFOLIO,
  successDvdPortfolio,
  failDvdPortfolio
} from "../Actions/portfolioDvd";

import { portfolioDvd } from "./api";

function* fetchPortfolioDvd({ payload }) {
  const { type, productSeq } = payload;
  try {
    const getList = yield call(portfolioDvd.getList, type);

    yield put(
      successDvdPortfolio({
        data: getList.map(item => {
          return {
            ...item,
            productSeq
          };
        })
      })
    );
  } catch (err) {
    console.log(err);
    yield put(failDvdPortfolio(err));
  }
}

export default function* watchFetchPortfolioDvd() {
  yield takeLatest(REQUEST_DVD_PORTFOLIO, fetchPortfolioDvd);
}
